import Button from 'components/ui/button/button';
import React from 'react';
import './landing-page.scss';
import PropTypes from 'prop-types';
import { getText } from 'helpers/ui-text-helper';

const LandingPage = ({startGame}) => {

	return (
		<div className='LandingPage'>
			<div className='LandingPage-logoWrapper'>
				<div className='LandingPage-logoAbsalon'/>
				<div className='LandingPage-logo'/>
				<div className='LandingPage-logoGameLab' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			</div>

			<div className='LandingPage-bottomPortion'>
				<div className='LandingPage-textDialogue'>
					<div className='LandingPage-text' dangerouslySetInnerHTML={
						{__html: getText('landingPageRules', 'gameRules')}
					}/>
				</div>
				<Button
					text={'Start'}
					classes={['basicGradient', 'small', 'responsive']}
					onClick={() => {startGame();}}
				/>
				<div/>
			</div>
		</div>
	);
};

LandingPage.propTypes = {
	startGame: PropTypes.func.isRequired
};

export default LandingPage;
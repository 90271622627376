import React, { useState } from 'react';
import './combined-result.scss';
import PropTypes from 'prop-types';
import { getMaxPoints, getPointThreshold } from 'helpers/point-helper';
import { getMissionFromId } from 'helpers/mission-helper';
import { getText } from 'helpers/ui-text-helper';

const CombinedResult = ({result, missions}) => {
	const resultValue = result.value.total ? result.value.total : result.value;
	const [showingHoverPopup, setShowingHoverPopup] = useState(false);

	// Converts given value to a number based on percentage of max points
	const convertToColorValue = (value) => {
		const percentage = value / getMaxPoints(missions) * 100;
		// Divides percentage 1-100 into 5 values, 1-5
		return getPointThreshold(percentage);
	};

	return (
		<div className={'CombinedResult ' + result.category.id}>
			{(missions && missions.length > 0) &&
				<div className='CombinedResult-completedMissionsWrapper'>
					<div className={'CombinedResult-title ' + result.category.id}>
						<span>{getText('finishedMissions', 'gameUi')}</span>
					</div>
					<div className='CombinedResult-completedMissions'>
						{missions.map((mission, index) => {
							const missionData = getMissionFromId(mission);
							let isCompleted = false;
							if (result.value.completedMissions && 
								result.value.completedMissions.find((completed) => {return completed === mission;})) {
								isCompleted = true;
							}

							return (
								<div key={index} className='CombinedResult-completedMission'>
									<div className={'CombinedResult-completedMissionIcon ' + 
										mission + 
										(isCompleted ? ' completed' : '')}
									/>
									<div className={'CombinedResult-completedMissionName ' + 
										(isCompleted ? ' completed' : '')}>
										<span>{missionData.title}</span>
									</div>
									<div className={'CombinedResult-missionIcon ' + (isCompleted ? 'check' : 'cross')}/>
								</div>
							);
						})}
					</div>
				</div>
			}
			<div className='CombinedResult-combinedResultWrapper'>
				<div className='CombinedResult-header'>
					<div className={'CombinedResult-title ' + result.category.id}>
						<span>{result.category.name}</span>
					</div>
					<div className='CombinedResult-infoIcon'
						onMouseEnter={() => {setShowingHoverPopup(true);}}
						onMouseOut={() => {setShowingHoverPopup(false);}}/>
				</div>
				<div className='CombinedResult-combinedResult'>
					<div className={'CombinedResult-resultValue color-' + convertToColorValue(resultValue)}>
						<span>{resultValue}</span>
						<div className={'CombinedResult-resultValueMax'}>
							<span>{getText('outOf', 'gameUi') + getMaxPoints(missions)}</span>
						</div>
					</div>
					<div className={'CombinedResult-resultSmiley smiley-' + convertToColorValue(resultValue)}></div>
				</div>
			</div>

			{showingHoverPopup &&
				<div className='CombinedResult-pointResultPopup'>
					<div className='CombinedResult-pointResultPopupArrow'/>
					<div className='CombinedResult-pointResultPopupContent'>
						<span>{getText('combinedResult', 'result')}</span>
					</div>
				</div>
			}
		</div>
	);
};

CombinedResult.propTypes = {
	result: PropTypes.object.isRequired,
	missions: PropTypes.array
};

export default CombinedResult;
const gameUiTexts = {
	choice: 'Valg',
	choose: 'Vælg',
	choose2: 'Vælg...',
	cookRecipe: 'tilbered ret',
	result: 'Resultat',
	savePdf: 'Gem som pdf',
	dish: 'Ret:',
	dish2: 'Ret',
	finishedRecipe: 'Din færdige ret',
	recipeAmount: '8-10 personer',
	mission: 'mission:',
	missions: 'missioner:',
	recipe: 'opskrift',
	price: 'God pris',
	co2: 'Klimavenlig',
	proteinQuality: 'Proteinkvalitet',
	mouthfeel: 'Mundfølelse',
	balance: 'Balance',
	tasteIntensity: 'Smagsintensitet',
	xFactor: 'X-factor',
	resetPopupText: 'Vil du nulstille og starte forfra?',
	outOf: 'Ud af ',
	finishedMissions: 'Fuldførte missioner',
};

const generalUiTexts = {
	back: 'Tilbage',
	adjust: 'Juster retten',
	continue: 'Videre',
	cancel: 'nej',
	confirm: 'ja'
};

const gameRulesTexts = {
	missionRules: `Vælg en eller flere missioner. <br />Hvis du gennemfører en mission, giver den ekstra point.`,
	landingPageRules: `Sammensæt den bedst mulige ret. Vælg mellem tre forskellige retter, og udvælg så ingredienser og tilbehør. Målet er at lave en ret der er velsmagende, klimavenlig, budgetvenlig og med høj proteinkvalitet.`
}

const resultTexts = {
	mouthfeel: 'Sprød og cremet tæller op, mens nøgleord som klæg og sej tæller ned.',
	balance: 'Er der balance mellem grundsmagene sødt, salt, surt, bittert og umami.',
	tasteIntensity: 'Har retten kraftfuld smag - fx pga. umami.',
	xFactor: 'Kan være stimulering af den trigeminale sans, fx stærke, varmende ingredienser.',

	co2: 'Viser om hver komponent har højt eller lavt CO2-aftryk.',
	price: 'Viser om hver komponent er budgetvenlig.',
	protein: 'Viser om hver komponent har høj proteinkvalitet. Komponenten med højeste proteinkvalitet giver den endelige score.',

	combinedResult: 'Smag, klimavenlighed, pris og proteinkvalitet lægges sammen for at få samlet score. En fuldført mission giver ekstra point.',
}

const cookieTexts = {
	cookieText: `Vi vil gerne gemme dine valgte handlinger i spillet i en cookie.<br />
	Du kan godt spille uden denne cookie, men så vil dine valg blive glemt, når du går væk fra siden.<br />
	Cookien bliver gemt i et år.<br /><br />
	Tryk "JA" for at acceptere den.<br />
	Tryk "NEJ" for at slette cookien og trække dit samtykke tilbage.`
}

const errorTexts = {
	unknownIngredientCategory: 'Unknown ingredient category',
	unknownRecipeId: 'Unknown recipe id: ',
};

export default {
	cookie: cookieTexts,
	result: resultTexts,
	generalUi: generalUiTexts,
	gameUi: gameUiTexts,
	gameRules: gameRulesTexts,
	errorTexts: errorTexts
}
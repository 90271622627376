/**
 * Save cookie data
 * @param {string} cname 
 * @param {string} cvalue 
 */
export function setCookie(cname, cvalue) {
	/* Expiration: 1 year */
	let year = new Date();
	year.setTime(year.getTime() + (365 * 24 * 60 * 60 * 1000));
	const expirationDateStr = year.toUTCString();
	const expires = 'expires=' + expirationDateStr;

	/* Save cookie */
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Get cookie data
 * @param {string} cname 
 * @returns 
 */
export function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

/**
 * Delete cookie
 * @param {string} cname 
 */
export function deleteCookie(cname) {
	document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
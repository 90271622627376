import { recipesData } from 'data/recipe-data';
import { getIngredientById } from './ingredient-helper';

export function getRecipeFromId(recipeId) {
	const recipe = recipesData.find((recipe) => {
		return recipe.id === recipeId;
	});

	return recipe;
};

export function getRecipeResult(ingredientChoices) {
	const combinedValues = {
		price: [],
		protein: [],
		co2: [],
		keywords: [],
	};

	ingredientChoices.forEach((choice) => {
		const data = getIngredientById(choice.ingredientId);

		data.values.forEach((value) => {
			combinedValues[value.id].push(value.value);
		});

		data.keywords.forEach((keyword) => {
			combinedValues.keywords.push(keyword);
		});
	});

	return combinedValues;
};
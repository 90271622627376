import React, { useEffect, useState } from 'react';
import './info-popup.scss';
import PropTypes from 'prop-types';
import { infoText } from 'data/info-data';
import iconI from '../../../assets/images/icons/icon-i.svg';

const InfoPopup = ({ closePopup, updatePlayerData}) => {
	// Duration of close animation in seconds
	const animationDuration = 0.15;
	const [closeTimeout, setCloseTimeout] = useState(null);
	const [isClosing, setIsClosing] = useState(false);
	const handleClosePopup = () => {
		setIsClosing(true);
		const newTimeout = setTimeout(() => {
			closePopup();
		}, 1000 * animationDuration);

		setCloseTimeout(newTimeout);
	};

	useEffect(() => {
		updatePlayerData({
			notReset: {
				hasSeenGameInfo: true
			}
		});
		return () => {
			if (closeTimeout) {
				clearTimeout(closeTimeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='InfoPopup' onClick={() => {handleClosePopup();}}>
			<div className={'InfoPopup-dialogueWrapper ' + (isClosing ? 'close' : '')}
				style={{animationDuration: animationDuration + 's'}}
				onClick={(e) => {e.stopPropagation();}}>
				<div className='InfoPopup-closeIcon' onClick={() => {handleClosePopup();}}/>
				<div className='InfoPopup-dialogue left'>
					{infoText.leftMargin.map((content, index) => {
						let text = content.text;
						if (content.hasImage) {
							text = text.replace(
								'!IMAGE!', 
								`<img src=${iconI} alt='i' style='width:1.2em; height:1.2em;'/>`
							);
						}

						return (
							<div key={index} className='InfoPopup-textWrapper'>
								{content.title &&
									<div className='InfoPopup-title'>
										<span>{content.title}</span>
									</div>
								}
								{content.text &&
									<div className='InfoPopup-insertedHtml' dangerouslySetInnerHTML={{__html: text}}/>
								}
								{content.colorInfo && 
									<div className='InfoPopup-colors'>
										{content.colorInfo.map((color, index) => {
											return (
												<div key={index} className={'InfoPopup-color ' + color}/>
											);
										})}
									</div>
								}
							</div>
						);
					})}
				</div>
				<div className='InfoPopup-dialogue right'>
					{infoText.rightMargin.map((content, index) => {
						return (
							<div key={index} className='InfoPopup-textWrapper'>
								{content.title &&
									<div className='InfoPopup-title'>
										<span>{content.title}</span>
									</div>
								}
								{content.text &&
									<div dangerouslySetInnerHTML={{__html: content.text}}/>
								}
								{content.colorInfo && 
									<div className='InfoPopup-colors'>
										{content.colorInfo.map((color, index) => {
											return (
												<div key={index} className={'InfoPopup-color ' + color}/>
											);
										})}
									</div>
								}
							</div>
						);
					})}
				</div>
				<div className='InfoPopup-dialogue center'>
					{infoText.centered.map((content, index) => {
						let styling = null;
						if (content.style && content.style.length > 0) {
							styling = [...content.style].toString().replace(',', ' ');
						}
						return (
							<div key={index} className={'InfoPopup-textWrapper ' + (styling ? styling : '')}>
								<span>{content.text}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

InfoPopup.propTypes = {
	closePopup: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
};

export default InfoPopup;
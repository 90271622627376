const { 
	meatballs, 
	sides, 
	greens, 
	sauce, 
	extras, 
	stew2, 
	sides3, 
	greens2, 
	toppings2, 
	extras2,
	stew,
	plates,
	topping,
	sides2,
} = require('./ingredients-data');

const recipesData = [
	{
		id: 'meatBalls',
		name: 'Frikadeller',
		componentLists: [
			meatballs,
			sides, 
			greens, 
			sauce, 
			extras
		],
		pointArrays: {
			price: {
				sumArray: 		[6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
				resultArray: 	[0, 1, 2, 4,  5,  6,  7,  8,  9, 10]
			},
			co2: {
				sumArray: 		[ 7,  8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
				resultArray: 	[10, 10, 9,  8,  7,  6,  5,  4,  3,  2,  1]
			}
		}
	},
	{
		id: 'curry',
		name: 'Karrygryde',
		componentLists: [
			stew2,
			sides3,
			greens2,
			toppings2,
			extras2
		],
		pointArrays: {
			price: {
				sumArray: 		[7, 8, 9, 10, 11, 12, 13, 14],
				resultArray: 	[0, 2, 4,  5,  6,  7,  8, 10]
			},
			co2: {
				sumArray: 		[ 7,  8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
				resultArray: 	[10, 10, 9,  8,  7,  6,  5,  4,  3,  2,  1]
			}
		}
	},
	{
		id: 'lasagna',
		name: 'Lasagne',
		componentLists: [
			stew,
			plates,
			topping,
			sides2,
		],
		pointArrays: {
			price: {
				sumArray: 		[5, 6, 7, 8, 9, 10, 11, 12],
				resultArray: 	[0, 2, 3, 5, 7,  8,  9, 10]
			},
			co2: {
				sumArray: 		[ 5,  6,  7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
				resultArray: 	[10, 10, 10, 9, 8,  7,  6,  5,  4,  3,  2,  1]
			}
		}
	}
];

module.exports = {
	recipesData,
}
import React, { useState } from 'react';
import './ingredient-choice.scss';
import PropTypes from 'prop-types';
import IngredientInfobox from '../ingredient-infobox/ingredient-infobox';

const IngredientChoice = ({ingredientData, handleIngredientChoice, isSelected}) => {
	const [isHoveringIngredient, setIsHoveringIngredient] = useState(false);

	return (
		<div className={'IngredientChoice' + (isSelected ? ' selected' : '')}
			onClick={() => {handleIngredientChoice(ingredientData);}}>
			<div className='IngredientChoice-ingredientContent'>
				<div className='IngredientChoice-infoIcon'
					onMouseEnter={() => {setIsHoveringIngredient(true);}}
					onMouseLeave={() => {setIsHoveringIngredient(false);}}/>

				{isSelected &&
					<div className='IngredientChoice-selectedMark'/>
				}
				<div className={'IngredientChoice-ingredientImage ' + ingredientData.id}/>
			</div>
			<div className='IngredientChoice-ingredientName'>
				<span>{ingredientData.name}</span>
			</div>

			{isHoveringIngredient &&
				<div className='IngredientChoice-infoBox'>
					<IngredientInfobox ingredientData={ingredientData}/>
				</div>
			}
		</div>
	);
};

IngredientChoice.propTypes = {
	ingredientData: PropTypes.object.isRequired,
	handleIngredientChoice: PropTypes.func.isRequired,
	isSelected: PropTypes.bool.isRequired,
};

export default IngredientChoice;
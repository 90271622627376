const { buildIngredient } = require("helpers/ingredient-helper")

const keywords = {
	umami: {
		id: 'umami',
		name: 'umami'
	},
	sweet: {
		id: 'sweet',
		name: 'sød'
	},
	floury: {
		id: 'floury',
		name: 'melet'
	},
	bitter: {
		id: 'bitter',
		name: 'bitter'
	},
	firm: {
		id: 'firm',
		name: 'fast'
	},
	elastic: {
		id: 'elastic',
		name: 'elastisk'
	},
	warm: {
		id: 'warm',
		name: 'varm'
	},
	sour: {
		id: 'sour',
		name: 'sur'
	},
	creamy: {
		id: 'creamy',
		name: 'cremet'
	},
	sticky: {
		id: 'sticky',
		name: 'klæg',
	},
	salty: {
		id: 'salty',
		name: 'salt'
	},
	crispy: {
		id: 'crispy',
		name: 'sprød',
	},
	powerful: {
		id: 'powerful',
		name: 'Kraftfuld smag'
	},
	warming: {
		id: 'warming',
		name: 'Varmende',
		isXFactor: true
	},
	tough: {
		id: 'tough',
		name: 'Sej',
	},
	dry: {
		id: 'dry',
		name: 'Tør'
	},
	soft: {
		id: 'soft',
		name: 'Blød'
	},
	juicy: {
		id: 'juicy',
		name: 'Saftig',
	},
	grainy: {
		id: 'grainy',
		name: 'Grynet',
	},
	cooling: {
		id: 'cooling',
		name: 'Kølende',
		isXFactor: true,
	}
}

const ingredients = {
	nothing: buildIngredient('nothing', 'Ingenting', [], {price: 3, protein: 0, co2: 1}),
	// stew2
	chickenCurry: buildIngredient(
		'chickenCurry',
		'Kylling i karry, klassisk',
		[keywords.umami, keywords.warming, keywords.umami, keywords.powerful],
		{price: 1, protein: 3, co2: 3}
	),
	curryPeaCauliflower: buildIngredient(
		'curryPeaCauliflower',
		'Curry med ærteprotein og blomkål',
		[keywords.umami, keywords.warming, keywords.bitter, keywords.sweet, keywords.creamy],
		{price: 1, protein: 2, co2: 2}
	),
	curryChickpeaCauliflowerPig: buildIngredient(
		'curryChickpeaCauliflowerPig',
		'Curry med kikærter, blomkål og svinekød',
		[keywords.umami, keywords.warming, keywords.sweet, keywords.creamy],
		{price: 2, protein: 3, co2: 3}
	),
	daalGreenLenses: buildIngredient(
		'daalGreenLenses',
		'Daal af grønne linser',
		[keywords.floury, keywords.bitter, keywords.warming],
		{price: 2, protein: '1X', co2: 2}
	),
	cabbageBaconApple: buildIngredient(
		'cabbageBaconApple',
		'Karrykål med bacon, æbler og kokosmælk',
		[keywords.umami, keywords.creamy, keywords.sweet, keywords.warming, keywords.bitter],
		{price: 2, protein: 3, co2: 3}
	),
	fishCurryCoconutSauce: buildIngredient(
		'fishCurryCoconutSauce',
		'Hvid torskefisk i karry-kokossauce med æbler',
		[keywords.umami, keywords.creamy, keywords.sweet, keywords.warming],
		{price: 1, protein: 3, co2: 4}
	),
	// sides3
	riceBrown: buildIngredient(
		'riceBrown',
		'Ris, brune',
		[keywords.salty, keywords.firm],
		{price: 3, protein: '1Y', co2: 3}
	),
	butterRiceApricot: buildIngredient(
		'butterRiceApricot',
		'Smørstegte ris med løg og abrikoser',
		[keywords.sweet, keywords.sweet, keywords.sour],
		{price: 1, protein: '1Y', co2: 3}
	),
	// toppings
	coconutFlakes: buildIngredient(
		'coconutFlakes',
		'Kokosflager',
		[keywords.sweet],
		{price: 3, protein: 1, co2: 4}
	),
	peanuts: buildIngredient(
		'peanuts',
		'Peanuts',
		[keywords.crispy, keywords.salty],
		{price: 3, protein: '1Y', co2: 3}
	),
	raisins: buildIngredient(
		'raisins',
		'Rosiner',
		[keywords.sweet],
		{price: 3, protein: 1, co2: 3}
	),
	honeyroastedSunflowerseeds: buildIngredient(
		'honeyroastedSunflowerseeds',
		'Honningristede solsikkekerner',
		[keywords.sweet, keywords.crispy],
		{price: 3, protein: '1Y', co2: 4}
	),
	dryMango: buildIngredient(
		'dryMango',
		'Tørret mango',
		[keywords.sweet, keywords.crispy],
		{price: 3, protein: 1, co2: 3}
	),
	freshBanana: buildIngredient(
		'freshBanana',
		'Banan, frisk',
		[keywords.sweet, keywords.soft],
		{price: 3, protein: 1, co2: 2}
	),
	// sides2
	spicyTomatoSalat: buildIngredient(
		'spicyTomatoSalat', 
		'Spicy tomatsalat', 
		[keywords.umami, keywords.sour, keywords.sweet, keywords.warming],
		{price: 1, protein: 1, co2: 2}
	),
	mintyoghurtAlmondsCucumberTomato: buildIngredient(
		'mintyoghurtAlmondsCucumberTomato', 
		'Mynteyoughurt med mandler, agurk og tomat',
		[keywords.sour, keywords.umami, keywords.creamy, keywords.cooling, keywords.crispy],
		{price: 2, protein: 3, co2: 2}
	),

	// lasagna plates
	lasagnaPlates: buildIngredient(
		'lasagnaPlates',
		'Lasagneplader, alm. uden æg',
		[keywords.firm, keywords.elastic],
		{price: 2, protein: '1Y', co2: 3}
	),
	potatoSlices: buildIngredient(
		'potatoSlices',
		'Kartofler, skiver',
		[keywords.umami],
		{price: 3, protein: 2, co2: 1}
	),
	aubergineSlices: buildIngredient(
		'aubergineSlices',
		'Auberginer, skiver',
		[keywords.bitter, keywords.sour, keywords.juicy],
		{price: 2, protein: 1, co2: 2}
	),
	lasagnaPlatesWholeGrain: buildIngredient(
		'lasagnaPlatesWholeGrain',
		'Lasagneplader, fuldkorn',
		[keywords.grainy, keywords.dry, keywords.firm],
		{price: 2, protein: '1Y', co2: 3}
	),
	cauliflowerSlices: buildIngredient(
		'cauliflowerSlices',
		'Blomkål i skiver',
		[keywords.sweet, keywords.soft],
		{price: 2, protein: 1, co2: 2}
	),
	roastedMushrooms: buildIngredient(
		'roastedMushrooms',
		'Ristede blandede svampe',
		[keywords.umami, keywords.umami, keywords.powerful],
		{price: 1, protein: 2, co2: 1}
	),

	// topping
	mozarella: buildIngredient(
		'mozarella',
		'Mozarella',
		[keywords.umami, keywords.creamy],
		{price: 3, protein: 3, co2: 5}
	),
	parmesanCheeseGrated: buildIngredient(
		'parmesanCheeseGrated',
		'Parmesanost, revet',
		[keywords.umami, keywords.umami, keywords.salty, keywords.powerful],
		{price: 2, protein: 3, co2: 5}
	),
	nutcrunchSage: buildIngredient(
		'nutcrunchSage',
		'Nøddecrunch med salvie',
		[keywords.bitter, keywords.crispy],
		{price: 2, protein: '1Y', co2: 4}
	),

	// stew
	bologneseBechamelCow: buildIngredient(
		'bologneseBechamelCow',
		'Bolognese med hakket oksekød + bechamel sauce',
		[keywords.umami, keywords.sweet, keywords.creamy, keywords.umami, keywords.powerful],
		{price: 1, protein: 3, co2: 5}
	),
	bologneseBechamelSquashCow: buildIngredient(
		'bologneseBechamelSquashCow',
		'Bolognese med 1/2 hakket oksekød og 1/2  hakket squash + bechamel sauce',
		[keywords.umami, keywords.sweet, keywords.creamy, keywords.umami, keywords.powerful],
		{price: 2, protein: 3, co2: 4}
	),
	bolognesePeaProtein: buildIngredient(
		'bolognesePeaProtein',
		'Bolognese med ærtebaseret plantefars + vegansk \'oste\'sauce',
		[keywords.umami, keywords.sweet, keywords.bitter, keywords.creamy],
		{price: 1, protein: 2, co2: 2,}
	),
	tomatosauceVeggieBechamel: buildIngredient(
		'tomatosauceVeggieBechamel',
		'Tomatsauce med rodfrugter og hvide bønner + bechamel sauce',
		[keywords.umami, keywords.sweet, keywords.sweet, keywords.floury, keywords.creamy],
		{price: 3, protein: 3, co2: 2}
	),
	tomatosauceVeganCheese: buildIngredient(
		'tomatosauceVeganCheese',
		'Tomatsauce med rodfrugter og hvide bønner + vegansk \'oste\'sauce',
		[keywords.umami, keywords.sweet, keywords.sweet, keywords.floury, keywords.creamy],
		{price: 2, protein: 2, co2: 2}
	),
	codSpinachTomatosauce: buildIngredient(
		'codSpinachTomatosauce',
		'Torsk med spinat-tomatsauce + bechamel',
		[keywords.umami, keywords.sweet, keywords.creamy, keywords.umami, keywords.powerful],
		{price: 1, protein: 3, co2: 3}
	),

	// meatballs
	choppedPig: buildIngredient(
		'choppedPig',
		'Fars, rørt, hakket gris',
		[keywords.umami, keywords.umami, keywords.sweet, keywords.powerful],
		{price: 3, protein: 3, co2: 3}
	),
	choppedCow: buildIngredient(
		'choppedCow',
		'Fars, rørt, hakket okse',
		[keywords.umami, keywords.umami, keywords.sweet, keywords.powerful],
		{price: 3, protein: 3, co2: 5}
	),
	choppedChicken: buildIngredient(
		'choppedChicken',
		'Fars, rørt, hakket kylling',
		[keywords.umami, keywords.umami, keywords.sweet, keywords.powerful],
		{price: 3, protein: 3, co2: 3}
	),
	choppedPigBeans: buildIngredient(
		'choppedPigBeans',
		'Fars, rørt, hakket gris + kidneybønner',
		[keywords.umami, keywords.umami, keywords.floury, keywords.sweet, keywords.powerful],
		{price: 3, protein: 3, co2: 3}
	),
	choppedLamb: buildIngredient(
		'choppedLamb',
		'Fars, rørt, hakket lam',
		[keywords.umami, keywords.umami, keywords.sweet, keywords.powerful],
		{price: 2, protein: 3, co2: 5}
	),
	choppedPigPeas: buildIngredient(
		'choppedPigPeas',
		'Fars, rørt, hakket gris + ærteprotein',
		[keywords.umami, keywords.umami, keywords.bitter, keywords.sweet, keywords.powerful],
		{price: 3, protein: 3, co2: 3}
	),
	choppedQuinoaChickpea: buildIngredient(
		'choppedQuinoaChickpea',
		'Fars, rørt, quinoa og kikærter',
		[keywords.umami, keywords.bitter],
		{price: 3, protein: 2, co2: 3}
	),
	choppedFish: buildIngredient(
		'choppedFish',
		'Fars, rørt, torskefisk',
		[keywords.umami, keywords.umami, keywords.powerful],
		{price: 1, protein: 3, co2: 4}
	),

	// sides 
	potatoBoiled: buildIngredient(
		'potatoBoiled',
		'Kartofler, hvide, kogte',
		[keywords.umami],
		{price: 3, protein: 2, co2: 1}
	),
	pastaStrips: buildIngredient(
		'pastaStrips',
		'Pasta uden æg, bånd',
		[keywords.firm, keywords.elastic],
		{price: 3, protein: '1Y', co2: 3}
	),
	lensesKale: buildIngredient(
		'lensesKale',
		'Cremede linser og grønkål i sennepssauce',
		[keywords.floury, keywords.bitter, keywords.warming, keywords.creamy, keywords.sour],
		{price: 1, protein: '1X', co2: 2}
	),
	potatoboats: buildIngredient(
		'potatoboats',
		'Kartoffelbåde, ovnbagte',
		[keywords.umami, keywords.sweet],
		{price: 3, protein: 2, co2: 1}
	),
	beanpastaScrews: buildIngredient(
		'beanpastaScrews',
		'Mungbønnepasta, skruer',
		[keywords.bitter, keywords.sticky, keywords.firm],
		{price: 1, protein: 2, co2: 3}
	),
	riceParboiled: buildIngredient(
		'riceParboiled',
		'Ris, parboiled',
		[keywords.salty],
		{price: 3, protein: '1Y', co2: 3}
	),
	eggnoodles: buildIngredient(
		'eggnoodles',
		'Ægnudler, kogte',
		[keywords.firm, keywords.elastic, keywords.umami],
		{price: 2, protein: 3, co2: 3}
	),

	// greens
	winterkaleApple: buildIngredient(
		'winterkaleApple',
		'Råkost af vinterkål og æble med vinagrette',
		[keywords.bitter, keywords.crispy, keywords.sweet, keywords.sour],
		{price: 3, protein: 1, co2: 2}
	),
	romaneSteamed: buildIngredient(
		'romaneSteamed',
		'Romanescoblanding, dampet',
		[keywords.crispy, keywords.sweet, keywords.bitter],
		{price: 3, protein: 1, co2: 2}
	),
	ovenbakedCarrots: buildIngredient(
		'ovenbakedCarrots',
		'Ovnbagte gulerødder med sesam',
		[keywords.sweet, keywords.umami, keywords.crispy],
		{price: 3, protein: '1Y', co2: 1}
	),
	pickledRedCabbage: buildIngredient(
		'pickledRedCabbage',
		'Syltet rødkål',
		[keywords.sour, keywords.sweet, keywords.bitter],
		{price: 3, protein: 1, co2: 2}
	),
	haricotSteamed: buildIngredient(
		'haricotSteamed',
		'Haricot vert, hele, dampede',
		[keywords.crispy],
		{price: 3, protein: '1X', co2: 2}
	),
	rawCarrotNutSalat: buildIngredient(
		'rawCarrotNutSalat',
		'Råkost med revet gulerødder, hasselnødder, æble og appelsin',
		[keywords.crispy, keywords.bitter, keywords.sour, keywords.sweet, keywords.crispy],
		{price: 3, protein: '1Y', co2: 2}
	),
	butterBrusselSprouts: buildIngredient(
		'butterBrusselSprouts',
		'Smørstegt rosenkål',
		[keywords.bitter, keywords.crispy],
		{price: 3, protein: 1, co2: 1}
	),
	cucumberSalat: buildIngredient(
		'cucumberSalat',
		'Agurksalat med lime og koriander',
		[keywords.sour, keywords.crispy],
		{price: 2, protein: 1, co2: 3}
	),
	bananaSalat: buildIngredient(
		'bananaSalat',
		'Banansalat med grønkål, jordskokker og lime',
		[keywords.sweet, keywords.bitter, keywords.sour, keywords.crispy],
		{price: 3, protein: 1, co2: 2}
	),
	friedMushrooms: buildIngredient(
		'friedMushrooms',
		'Ristede svampe og porrer',
		[keywords.umami, keywords.umami, keywords.bitter, keywords.powerful],
		{price: 2, protein: 2, co2: 2}
	),

	// Sauce
	skysauce: buildIngredient(
		'skysauce',
		'Skysauce',
		[keywords.umami],
		{price: 3, protein: 3, co2: 2}
	),
	stewedCabbageSpinach: buildIngredient(
		'stewedCabbageSpinach',
		'Stuvet hvidkål og spinat',
		[keywords.bitter, keywords.creamy, keywords.sweet],
		{price: 2, protein: 3, co2: 2}
	),
	tomatoSauceParmasan: buildIngredient(
		'tomatoSauceParmasan',
		'Tomatsauce + parmesanost',
		[keywords.umami, keywords.umami, keywords.salty, keywords.powerful],
		{price: 1, protein: 3, co2: 3}
	),
	remouladeSauce: buildIngredient(
		'remouladeSauce',
		'Remouladesauce, varm',
		[keywords.sweet, keywords.sour, keywords.creamy],
		{price: 2, protein: 3, co2: 3}
	),
	sennepCreme: buildIngredient(
		'sennepCreme',
		'Senneps-creme fraichesauce, kold',
		[keywords.creamy, keywords.warming],
		{price: 2, protein: 3, co2: 3}
	),

	// Extras
	mangoChutney: buildIngredient(
		'mangoChutney',
		'Mangochutney, sød',
		[keywords.sweet, keywords.warming,],
		{price: 1, protein: 1, co2: 2}
	),
	pumpkinMarmalade: buildIngredient(
		'pumpkinMarmalade',
		'Græskarmarmelade med appelsin, citron, kanel og ingefær',
		[keywords.sweet, keywords.sour, keywords.warming],
		{price: 3, protein: 1, co2: 2}
	),
	pickledLingonberries: buildIngredient(
		'pickledLingonberries',
		'Syltede tyttebær',
		[keywords.sour, keywords.sour],
		{price: 2, protein: 1, co2: 2}
	),
	cottageCheese: buildIngredient(
		'cottageCheese',
		'Hytteost, 4%',
		[keywords.umami, keywords.creamy, keywords.sour],
		{price: 3, protein: 3, co2: 3}
	),
	pickledAsier: buildIngredient(
		'pickledAsier',
		'Syltede asier',
		[keywords.sour, keywords.sour, keywords.crispy,],
		{price: 2, protein: 1, co2: 3}
	),
};

// CURRY RECIPE
const stew2 = {
	id: 'stew2',
	name: 'gryderet',
	ingredients: [
		ingredients.chickenCurry,
		ingredients.curryPeaCauliflower,
		ingredients.curryChickpeaCauliflowerPig,
		ingredients.daalGreenLenses,
		ingredients.cabbageBaconApple,
		ingredients.fishCurryCoconutSauce
	]
};

const sides3 = {
	id: 'sides3',
	name: 'tilbehør',
	ingredients: [
		ingredients.riceParboiled,
		ingredients.riceBrown,
		ingredients.butterRiceApricot,
		ingredients.potatoboats,
		ingredients.pastaStrips,
		ingredients.beanpastaScrews,
		ingredients.potatoBoiled,
		ingredients.eggnoodles,
	]
};

const greens2 = {
	id: 'greens2',
	name: 'grøntsager',
	ingredients: [
		ingredients.winterkaleApple,
		ingredients.romaneSteamed,
		ingredients.haricotSteamed,
		ingredients.ovenbakedCarrots,
		ingredients.mintyoghurtAlmondsCucumberTomato,
		ingredients.rawCarrotNutSalat,
		ingredients.butterBrusselSprouts,
		ingredients.cucumberSalat,
		ingredients.bananaSalat,
		ingredients.friedMushrooms,
	]
};

const toppings2 = {
	id: 'toppings2',
	name: 'topping',
	ingredients: [
		ingredients.nothing,
		ingredients.coconutFlakes,
		ingredients.peanuts,
		ingredients.raisins,
		ingredients.honeyroastedSunflowerseeds,
		ingredients.dryMango,
		ingredients.freshBanana,
	]
}

const extras2 = {
	id: 'extras2',
	name: null,
	ingredients: [
		ingredients.mangoChutney,
		ingredients.pumpkinMarmalade,
		ingredients.pickledLingonberries,
		ingredients.cottageCheese,
		ingredients.pickledAsier,
	]
}

// LASAGNA RECIPE
const stew = {
	id: 'stew',
	name: 'gryderet',
	ingredients: [
		ingredients.bologneseBechamelCow,
		ingredients.bologneseBechamelSquashCow,
		ingredients.bolognesePeaProtein,
		ingredients.tomatosauceVeggieBechamel,
		ingredients.tomatosauceVeganCheese,
		ingredients.codSpinachTomatosauce,
	]
};

const plates = {
	id: 'plates',
	name: 'plader',
	ingredients: [
		ingredients.lasagnaPlates,
		ingredients.potatoSlices,
		ingredients.aubergineSlices,
		ingredients.lasagnaPlatesWholeGrain,
		ingredients.cauliflowerSlices,
		ingredients.beanpastaScrews,
		ingredients.roastedMushrooms,
	]
};

const topping = {
	id: 'topping',
	name: 'topping',
	ingredients: [
		ingredients.nothing,
		ingredients.mozarella,
		ingredients.cottageCheese,
		ingredients.parmesanCheeseGrated,
		ingredients.nutcrunchSage,
	]
};

const sides2 = {
	id: 'sides2',
	name: 'tilbehør',
	ingredients: [
		ingredients.spicyTomatoSalat,
		ingredients.winterkaleApple,
		ingredients.romaneSteamed,
		ingredients.haricotSteamed,
		ingredients.ovenbakedCarrots,
		ingredients.mintyoghurtAlmondsCucumberTomato,
		ingredients.rawCarrotNutSalat,
		ingredients.butterBrusselSprouts,
		ingredients.cucumberSalat,
		ingredients.bananaSalat,
		ingredients.friedMushrooms,
	]
};

// MEATBALLS RECIPE
const meatballs = {
	id: 'meatBalls',
	name: 'frikadeller',
	ingredients: [
		ingredients.choppedPig,
		ingredients.choppedCow,
		ingredients.choppedChicken,
		ingredients.choppedPigBeans,
		ingredients.choppedLamb,
		ingredients.choppedPigPeas,
		ingredients.choppedQuinoaChickpea,
		ingredients.choppedFish,
	]
};

const sides = {
	id: 'sides',
	name: 'tilbehør',
	ingredients: [
		ingredients.nothing,
		ingredients.potatoBoiled,
		ingredients.pastaStrips,
		ingredients.lensesKale,
		ingredients.potatoboats,
		ingredients.beanpastaScrews,
		ingredients.riceParboiled,
		ingredients.eggnoodles,
	]
};

const greens = {
	id: 'greens',
	name: 'grøntsager',
	ingredients: [
		ingredients.winterkaleApple,
		ingredients.romaneSteamed,
		ingredients.ovenbakedCarrots,
		ingredients.pickledRedCabbage,
		ingredients.haricotSteamed,
		ingredients.rawCarrotNutSalat,
		ingredients.butterBrusselSprouts,
		ingredients.cucumberSalat,
		ingredients.bananaSalat,
		ingredients.friedMushrooms
	]
};

const sauce = {
	id: 'sauce',
	name: 'sovs',
	ingredients: [
		ingredients.nothing,
		ingredients.skysauce,
		ingredients.stewedCabbageSpinach,
		ingredients.tomatoSauceParmasan,
		ingredients.remouladeSauce,
		ingredients.sennepCreme,
	]
};

const extras = {
	id: 'extras',
	name: null,
	ingredients: [
		ingredients.nothing,
		ingredients.mangoChutney,
		ingredients.pumpkinMarmalade,
		ingredients.pickledLingonberries,
		ingredients.cottageCheese,
		ingredients.pickledAsier,
	]
};

export {
	// Lasagna
	ingredients,
	stew,
	plates,
	sides2,
	topping,
	// Meatballs
	meatballs,
	sides,
	greens, 
	sauce, 
	extras,
	// Curry Stew
	stew2,
	sides3,
	greens2,
	toppings2,
	extras2,
};
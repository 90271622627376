const missionData = [
	{
		id: 'cheap',
		title: 'Budgetvenlig',
		details: 'Gennemfør missionen ved at få 7 point eller højere i budgetvenlig.',
		categoryId: 'price',
	},
	{
		id: 'climate',
		title: 'Klimavenlig',
		details: 'Gennemfør missionen ved at få 7 point eller højere i klimavenlig.',
		categoryId: 'co2',
	},
	{
		id: 'tasty',
		title: 'Velsmag',
		details: 'Gennemfør missionen ved at få 7 point eller højere i smag.',
		categoryId: 'taste',
	}
];

module.exports = {missionData};
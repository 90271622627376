const valuesTemplate = {
	price: {
		name: 'pris',
		value: null,
	},
	protein: {
		name: 'proteinkvalitet',
		value: null,
	},
	co2: {
		name: 'CO2',
		value: null,
	}
};

export {
	valuesTemplate
};
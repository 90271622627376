import React, { useEffect, useState } from 'react';
import './choice-info.scss';
import PropTypes from 'prop-types';
import { getRecipeFromId } from 'helpers/recipe-helper';
import { getMissionFromId } from 'helpers/mission-helper';
import { getText } from 'helpers/ui-text-helper';

const ChoiceInfo = ({playerData}) => {
	const [recipeData, setRecipeData] = useState(null);

	// Gets data from playerData id's
	useEffect(() => {
		// Get recipe data
		if (playerData.recipeId) {
			const chosenRecipe = getRecipeFromId(playerData.recipeId);
			setRecipeData(chosenRecipe);
		}
	}, [playerData.recipeId]);

	return (
		<div className='ChoiceInfo'>
			<div className='ChoiceInfo-title'>
				<span>{getText('choice', 'gameUi')}</span>
			</div>

			{recipeData &&
				<div className='ChoiceInfo-choice'>
					<div className='ChoiceInfo-choiceTitle'>
						<span>{getText('dish', 'gameUi')}</span>
					</div>
					<div className='ChoiceInfo-choiceName'>
						<span>{recipeData.name}</span>
					</div>
				</div>
			}

			{((playerData.choseMissions && playerData.missions) && playerData.missions.length > 0) && 
				<div className='ChoiceInfo-choice'>
					<div className='ChoiceInfo-choiceTitle'>
						<span>{playerData.missions.length === 1 ? 
							getText('mission', 'gameUi') 
							: 
							getText('missions', 'gameUi')}
						</span>
					</div>
					<div className='ChoiceInfo-choiceNameWrapper'>
						{playerData.missions.map((mission, index) => {
							const missionData = getMissionFromId(mission);

							return (
								<div key={index} className='ChoiceInfo-choiceName wrapped'>
									<span>{missionData.title}</span>
								</div>
							);
						})}
					</div>
				</div>
			}
		</div>
	);
};

ChoiceInfo.propTypes = {
	playerData: PropTypes.object.isRequired,
};

export default ChoiceInfo;
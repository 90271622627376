import React from 'react';
import './ingredient-infobox.scss';
import PropTypes from 'prop-types';
import { getText } from 'helpers/ui-text-helper';

const IngredientInfobox = ({ingredientData, className = ''}) => {

	return (
		<div className={'IngredientInfobox ' + className}>
			<div className={'IngredientInfobox-wrapper'}>
				<div className='IngredientInfobox-infoBoxItem'>
					<span>{getText('price', 'gameUi')}</span>
					<div className={'IngredientInfobox-infoBoxValue price-' + ingredientData.values.price.value}/>
				</div>
				<div className='IngredientInfobox-infoBoxItem'>
					<span>{getText('co2', 'gameUi')}</span>
					<div className={'IngredientInfobox-infoBoxValue co2-' + ingredientData.values.co2.value}/>
				</div>
				<div className='IngredientInfobox-infoBoxItem'>
					<span>{getText('proteinQuality', 'gameUi')}</span>
					<div className={'IngredientInfobox-infoBoxValue protein-' + 
						ingredientData.values.protein.value
					}/>
				</div>
				<div className='IngredientInfobox-infoBoxKeywords'>
					{ingredientData.keywords.map((keyword, index) => {
						return (
							<div key={index} className='IngredientInfobox-infoBoxKeyword'>
								<span>{keyword.name}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

IngredientInfobox.propTypes = {
	ingredientData: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default IngredientInfobox;
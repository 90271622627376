import React from 'react';
import './game-logo.scss';
import PropTypes from 'prop-types';

const GameLogo = ({openResetPopup}) => {
	return (
		<div className='GameLogo' onClick={() => {openResetPopup();}}/>
	);
};

GameLogo.propTypes = {
	openResetPopup: PropTypes.func.isRequired,
};

export default GameLogo;
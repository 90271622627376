const ingredientDetails = [
	{
		id: 'choppedPig',
		ingredients: [
			{
				name: 'Gris, hakket, 12%',
				amount: 0.75,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Æg, hel',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.225,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.09,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedCow',
		ingredients: [
			{
				name: 'Okse, hakket, 12%',
				amount: 0.75,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Æg, hel',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.225,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.09,
				unit: 'kg',
			}
		]
	},
	{
		id: 'choppedChicken',
		ingredients: [
			{
				name: 'Kylling, hakket',
				amount: 0.75,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Æg, hel',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.225,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.09,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedLamb',
		ingredients: [
			{
				name: 'Lammekød, hakket (2% kartoffel)',
				amount: 0.75,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Æg, hel',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.225,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.09,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedPigBeans',
		ingredients: [
			{
				name: 'Kidneybønner, tørrede',
				amount: 0.17,
				unit: 'kg',
			},
			{
				name: 'Gris, hakket (12% fedt)',
				amount: 0.45,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.13,
				unit: 'kg',
			},
			{
				name: 'Æg',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Rasp',
				amount: 0.14,
				unit: 'kg',
			},
			{
				name: 'Fløde',
				amount: 0.2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedPigPeas',
		ingredients: [
			{
				name: 'Planteprotein (ærte/bønne) Plantmate, ekstrudater (minced)',
				amount: 0.17,
				unit: 'kg',
			},
			{
				name: 'Gris, hakket (12% fedt)',
				amount: 0.45,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.13,
				unit: 'kg',
			},
			{
				name: 'Æg',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Rasp',
				amount: 0.14,
				unit: 'kg',
			},
			{
				name: 'Fløde',
				amount: 0.2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedQuinoaChickpea',
		ingredients: [
			{
				name: 'Quinoa (hvid)',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Kikærter, kogte',
				amount: 0.48,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon (færdigblandet)',
				amount: 0.35,
				unit: 'kg',
			},
			{
				name: 'Æg, hel',
				amount: 0.25,
				unit: 'kg',
			},
			{
				name: 'Rødløg, finthakkede (hele)',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, presset (hele)',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Persille, finthakket (bredbladet)',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Spidskommen, stødt',
				amount: 0.007,
				unit: 'kg',
			},
			{
				name: 'Timian, tørret',
				amount: 0.007,
				unit: 'kg',
			},
			{
				name: 'Oregano, tørret',
				amount: 0.007,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Feta (ægte!) (salatost - 53,58 kr./kg)',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Olie, smagsneutral (rapsolie)',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'choppedFish',
		ingredients: [
			{
				name: 'Torsk (eller kuller, sej eller kulmule)',
				amount: 1.2,
				unit: 'kg',
			},
			{
				name: 'Æg',
				amount: 0.24,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.34,
				unit: 'kg',
			},
			{
				name: 'Fløde, 38%',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Gulerod',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Kartoffel, bage',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Dild, frisk',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.03,
				unit: 'kg',
			},
		]
	},
	{
		id: 'potatoBoiled',
		ingredients: [
			{
				names: [
					{
						recipeId: 'meatBalls',
						name: 'Kartofler, kogte (40-60 mm.)',
					},
					{
						recipeId: 'curry',
						name: 'Kartofler, hvide, kogte (40-60mm. rå kartofler)'
					}
				],
				amounts: [
					{
						recipeId: 'meatBalls',
						amount: 2,
					},
					{
						recipeId: 'curry',
						amount: 1,
					}
				],
				unit: 'kg',
			},
		]
	},
	{
		id: 'beanpastaScrews',
		ingredients: [
			{
				name: 'Mungbønnepasta, skruer',
				amount: 0.8,
				unit: 'kg',
			},
		]
	},
	{
		id: 'riceParboiled',
		ingredients: [
			{
				name: 'Ris, parboiled',
				amount: 0.7,
				unit: 'kg',
			},
		]
	},
	{
		id: 'eggnoodles',
		ingredients: [
			{
				name: 'Æg-nudler, kogte',
				amount: 0.8,
				unit: 'kg',
			},
		]
	},
	{
		id: 'lensesKale',
		ingredients: [
			{
				name: 'Puy-linser, tørrede (grønne)',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Grønkål, snittet',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Mel',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Fløde',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Grov sennep',
				amount: 0.06,
				unit: 'kg',
			},
			{
				name: 'Æbleeddike',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Planteolie, til stegning (rapsolie)',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'potatoboats',
		ingredients: [
			{
				name: 'Kartofler, med skræl (40-60 mm)',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Olie, smagsneutral (rapsolie)',
				amount: 0.02,
				unit: 'kg',
			}
		]
	},
	{
		id: 'winterkaleApple',
		ingredients: [
			{
				name: 'Hvidkål, fintsnittet',
				amount: 0.565,
				unit: 'kg',
			},
			{
				name: 'Persille, hakket',
				amount: 0.025,
				unit: 'kg',
			},
			{
				name: 'Æbler, i tynde skiver (Ingrid Marie)',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Sukker',
				amount: 0.013,
				unit: 'kg',
			},
			{
				name: 'Olivenolie',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Æbleeddike',
				amount: 0.055,
				unit: 'kg',
			},
		]
	},
	{
		id: 'romaneSteamed',
		ingredients: [
			{
				name: 'Romanesco, buketter',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Blomkål, buketter',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Gulerødder, karotter',
				amount: 0.3,
				unit: 'kg',
			},
		]
	},
	{
		id: 'ovenbakedCarrots',
		ingredients: [
			{
				name: 'Gulerødder',
				amount: 0.75,
				unit: 'kg',
			},
			{
				name: 'Olie, smagsneutral',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Sesamfrø',
				amount: 0.02,
				unit: 'kg',
			},
		]
	},
	{
		id: 'pickledRedCabbage',
		ingredients: [
			{
				name: 'Rødkål, fintsnittet',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Æbleeddike',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Sukker',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Laurbærblade',
				amount: 0.004,
				unit: 'kg',
			},
		]
	},
	{
		id: 'haricotSteamed',
		ingredients: [
			{
				name: 'Grønne bønner, hele',
				amount: 0.75,
				unit: 'kg',
			},
		]
	},
	{
		id: 'rawCarrotNutSalat',
		ingredients: [
			{
				name: 'Gulerødder, revet',
				amount: 0.7,
				unit: 'kg',
			},
			{
				name: 'Æbler, røde, hakket (Ingrid Marie)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Appelsin, kød',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hasselnødder, hakket',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'butterBrusselSprouts',
		ingredients: [
			{
				name: 'Rosenkål, friske, kvarte',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'cucumberSalat',
		ingredients: [
			{
				name: 'Agurk',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Æbler, grønne (Granny Smith)',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Koriander, frisk, hakket',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Rødløg',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Lime, saft',
				amount: 0.08,
				unit: 'kg',
			},
			{
				name: 'Melis, stødt',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Eddike, hvid',
				amount: 0.025,
				unit: 'kg',
			},
		]
	},
	{
		id: 'bananaSalat',
		ingredients: [
			{
				name: 'Banan, skiver',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Grønkål, uden stilk',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Jordskokke, skrællet, skiver',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Lime, saft',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'friedMushrooms',
		ingredients: [
			{
				name: 'Svampe, champignon, marksvampe, østershatte, kejserhatte',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Porrer',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'skysauce',
		ingredients: [
			{
				name: 'Smør',
				amount: 0.038,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.045,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Kulør',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Ribsgele',
				amount: 0.02,
				unit: 'kg',
			},
		]
	},
	{
		id: 'stewedCabbageSpinach',
		ingredients: [
			{
				name: 'Hvidkål, snittet',
				amount: 1.5,
				unit: 'kg',
			},
			{
				name: 'Spinat, skyllet',
				amount: 0.25,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.075,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.075,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.075,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, revet',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Citron, skal (økologiske)',
				amount: 0.015,
				unit: 'kg',
			},
		]
	},
	{
		id: 'tomatoSauceParmasan',
		ingredients: [
			{
				name: 'Olivenolie',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakket',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Eddike, sherry',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Oregano, tørret',
				amount: 0.007,
				unit: 'kg',
			},
			{
				name: 'Persille, hakket',
				amount: 0.014,
				unit: 'kg',
			},
			{
				name: 'Hakkede tomater, dåse',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Parmesanost, revet',
				amount: 0.25,
				unit: 'kg',
			},
		]
	},
	{
		id: 'remouladeSauce',
		ingredients: [
			{
				name: 'Smør',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Sødmælk',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, stødt',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Picalilly (remouladepure, grov)',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'sennepCreme',
		ingredients: [
			{
				name: 'Sennepskorn, gule',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Creme fraiche (8%)',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Dijonsennep, mild',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Eddike, hvidvin',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Purløg',
				amount: 0.05,
				unit: 'kg',
			},
		]
	},
	{
		id: 'mangoChutney',
		ingredients: [
			{
				name: 'Agurk',
				amount: 0.55,
				unit: 'kg',
			},
			{
				name: 'Cocktailtomater, grofthakket (her cherry)',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Løg, grofthakket',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Mango, tern',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Ingefær, frisk, revet',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Rørsukker',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Lagereddike, hvid',
				amount: 0.34,
				unit: 'kg',
			},
			{
				name: 'Chiliflager',
				amount: 0.001,
				unit: 'kg',
			},
		]
	},
	{
		id: 'pumpkinMarmalade',
		ingredients: [
			{
				name: 'Græskar (hokkaido)',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Melis, stødt',
				amount: 0.25,
				unit: 'kg',
			},
			{
				name: 'Appelsin, frugtkød',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Citron, frugtkød',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Ingefær, frisk',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Kanel',
				amount: 0.004,
				unit: 'kg',
			},
		]
	},
	{
		id: 'pickledLingonberries',
		ingredients: [
			{
				name: 'Tyttebær (frosne)',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Rørsukker',
				amount: 0.15,
				unit: 'kg',
			},
		]
	},
	{
		id: 'cottageCheese',
		ingredients: [
			{
				name: 'Hytteost, 4%',
				amounts: [
					{
						recipeId: 'lasagna',
						amount: 0.3
					},
					{
						recipeId: 'curry',
						amount: 0.5
					},
					{
						recipeId: 'meatBalls',
						amount: 0.5
					}
				],
				unit: 'kg',
			},
		]
	},
	{
		id: 'pickledAsier',
		ingredients: [
			{
				name: 'asier',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Eddike, lager (hvid)',
				amount: 0.25,
				unit: 'kg',
			},
			{
				name: 'Rørsukker',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Sennepskorn, gule',
				amount: 0.036,
				unit: 'kg',
			},
			{
				name: 'Stjerneanis',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Peberkorn, sorte',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Timian, frisk, kviste',
				amount: 0.02,
				unit: 'kg',
			},
		]
	},
	{
		id: 'chickenCurry',
		ingredients: [
			{
				name: 'Kylling, inderfilet',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.08,
				unit: 'kg',
			},
			{
				name: 'Karry, Bombay',
				amount: 0.013,
				unit: 'kg',
			},
			{
				name: 'Løg, hakket (hele zittauer 5,11 kr. pr. kg)',
				amount: 0.9,
				unit: 'kg',
			},
			{
				name: 'Sukker, stødt',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Æbler, uskrællet i tern (Granny Smith - grønne)',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Sødmælk',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Vand',
				amount: 0.5,
				unit: 'kg',
			},
		]
	},
	{
		id: 'curryPeaCauliflower',
		ingredients: [
			{
				name: 'Planteprotein (ærte) Plantmate, ekstrudater',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Løg, snittede (hele zittauer 5,11 kr. pr. kg)',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Ingefær, frisk, revet (hele)',
				amount: 0.048,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (hele)',
				amount: 0.035,
				unit: 'kg',
			},
			{
				name: 'Kokosmælk',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Chilipulver',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Spidskommen, stødt',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Gurkemeje',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Korianderfrø',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Karry',
				amount: 0.023,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon (færdig og klar til brug)',
				amount: 1.3,
				unit: 'kg',
			},
			{
				name: 'Blomkål, i buketter',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Citron, saft (flaske)',
				amount: 0.115,
				unit: 'kg',
			},
			{
				name: 'Grønkål, ribbet',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.045,
				unit: 'kg',
			},
		]
	},
	{
		id: 'curryChickpeaCauliflowerPig',
		ingredients: [
			{
				name: 'Skinkekød, tern',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Kikærter, tørrede',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Løg, snittede (hele zittauer)',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Ingefær, frisk, revet (hele)',
				amount: 0.048,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (hele)',
				amount: 0.035,
				unit: 'kg',
			},
			{
				name: 'Kokosmælk',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Chilipulver',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Spidskommen, stødt',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Gurkemeje',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Korianderfrø',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Karry',
				amount: 0.023,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon',
				amount: 1.3,
				unit: 'kg',
			},
			{
				name: 'Blomkål, i buketter',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Citron, saft',
				amount: 0.115,
				unit: 'kg',
			},
			{
				name: 'Grønkål, ribbet',
				amount: 0.6,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.045,
				unit: 'kg',
			},
		]
	},
	{
		id: 'daalGreenLenses',
		ingredients: [
			{
				name: 'Løg, snittede (hele zittauer)',
				amount: 0.7,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Ingefær, frisk, revet (hele)',
				amount: 0.045,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (hele)',
				amount: 0.035,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.7,
				unit: 'kg',
			},
			{
				name: 'Chilipulver',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Citron, saft',
				amount: 0.115,
				unit: 'kg',
			},
			{
				name: 'Spidskommen, stødt',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Gurkemeje',
				amount: 0.007,
				unit: 'kg',
			},
			{
				name: 'Korianderfrø',
				amount: 0.015,
				unit: 'kg',
			},
			{
				name: 'Karry',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Hønsebouillon (færdigblandet)',
				amount: 1.5,
				unit: 'kg',
			},
			{
				name: 'Grønne linser',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Ærter, fra frost',
				amount: 1,
				unit: 'kg',
			},
		]
	},
	{
		id: 'cabbageBaconApple',
		ingredients: [
			{
				name: 'Æbler, i tern (Granny Smith - grønne)',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Hvidkål, snittet',
				amount: 1.3,
				unit: 'kg',
			},
			{
				name: 'Røget svinebryst (bacon), i tern (et helt stykke)',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Høneboullion',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Løg, groft hakket (hele zittauer)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Kokosmælk',
				amount: 0.35,
				unit: 'kg',
			},
			{
				name: 'Karry',
				amount: 0.01,
				unit: 'kg',
			},
		]
	},
	{
		id: 'fishCurryCoconutSauce',
		ingredients: [
			{
				name: 'Torskefisk, fx. mørksej eller lange (uden skind og ben)',
				amount: 0.9,
				unit: 'kg',
			},
			{
				name: 'Gulerødder',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Porrer',
				amount: 0.3,
				unit: 'kg',
			},
			{
				name: 'Æbler (Granny Smith - grønne)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Kokosmælk',
				amount: 0.8,
				unit: 'kg',
			},
			{
				name: 'Karry',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsboullion',
				amount: 0.02,
				unit: 'kg',
			},
		]
	},
	{
		id: 'riceBrown',
		ingredients: [
			{
				name: 'Ris, brune',
				amount: 0.7,
				unit: 'kg',
			},
		]
	},
	{
		id: 'pastaStrips',
		ingredients: [
			{
				name: 'Pasta, bånd',
				amount: 0.8,
				unit: 'kg',
			},
		]
	},
	{
		id: 'butterRiceApricot',
		ingredients: [
			{
				name: 'Basmatiris',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Safran',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.04,
				unit: 'kg',
			},
			{
				name: 'Løg, i skiver (hele zittauer)',
				amount: 0.1,
				unit: 'kg',
			},
			{
				name: 'Olie, smagsneutral (solsikke)',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Abrikoser, tørret, strimler',
				amount: 0.1,
				unit: 'kg',
			},
		]
	},
	{
		id: 'mintyoghurtAlmondsCucumberTomato',
		ingredients: [
			{
				name: 'Græsk yoghurt 10%',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Mynte, frisk, plukket',
				amount: 0.025,
				unit: 'kg',
			},
			{
				name: 'Mandler, saltede, hakkede',
				amount: 0.04,
				unit: 'kg',
			},
			{
				name: 'Agurk, hakket',
				amount: 0.4,
				unit: 'kg',
			},
			{
				name: 'Tomat, hakket',
				amount: 0.3,
				unit: 'kg',
			},
		]
	},
	{
		id: 'coconutFlakes',
		ingredients: [
			{
				name: 'Kokos, flager',
				amount: 0.1,
				unit: 'kg',
			},
		]
	},
	{
		id: 'peanuts',
		ingredients: [
			{
				name: '',
				amount: 0.12,
				unit: 'kg',
			},
		]
	},
	{
		id: 'raisins',
		ingredients: [
			{
				name: 'Rosiner',
				amount: 0.12,
				unit: 'kg',
			},
		]
	},
	{
		id: 'dryMango',
		ingredients: [
			{
				name: 'Mango, tørret',
				amount: 0.1,
				unit: 'kg',
			},
		]
	},
	{
		id: 'freshBanana',
		ingredients: [
			{
				name: 'Banan, skiver',
				amount: 0.2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'honeyroastedSunflowerseeds',
		ingredients: [
			{
				name: 'Solsikkekerner',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Honning, flydende',
				amount: 0.015,
				unit: 'kg',
			},
		]
	},
	{
		id: 'bologneseBechamelCow',
		ingredients: [
			{
				name: 'Oksekød, hakket, 6% fedt',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Løg, hakkede (hele zittauer)',
				amount: 0.39,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (her hele hvidløg)',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.63,
				unit: 'kg',
			},
			{
				name: 'Tomatpuré',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Chiliflager (som sort peber)',
				amount: 0.004,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Sødmælk',
				amount: 1.5,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, stødt',
				amount: 0.003,
				unit: 'kg',
			},
		]
	},
	{
		id: 'bologneseBechamelSquashCow',
		ingredients: [
			{
				name: 'Oksekød, hakket, 6% fedt',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Squash, hakket (her hel squash)',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Løg, hakkede (hele zittauer)',
				amount: 0.39,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (her hele hvidløg)',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.63,
				unit: 'kg',
			},
			{
				name: 'Tomatpuré',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Chiliflager',
				amount: 0.004,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Sødmælk',
				amount: 1.5,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, stødt',
				amount: 0.003,
				unit: 'kg',
			},
		]
	},
	{
		id: 'bolognesePeaProtein',
		ingredients: [
			{
				name: 'Planteprotein (ærte/bønne) Plantmate, ekstrudater (minced)',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Løg, hakkede (hele zittauer)',
				amount: 0.39,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (her hele hvidløg)',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.63,
				unit: 'kg',
			},
			{
				name: 'Tomatpuré',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Chiliflager',
				amount: 0.004,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Ukogte kartofler (40-60mm)',
				amount: 0.7,
				unit: 'kg',
			},
			{
				name: 'Kogevand (fra kartofler)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Gærflager',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Løgpulver',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, presset',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon (færdigblandet)',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'LYS tahin',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Dijonsennep',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Æblecidereddike',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, revet',
				amount: 0.005,
				unit: 'kg',
			},
		]
	},
	{
		id: 'tomatosauceVeggieBechamel',
		ingredients: [
			{
				name: 'Hvide bønner, tørrede (som kidney bønner)',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Knoldselleri, i tern (her hele)',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Gulerødder, i tern (her hele)',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Persillerødder, i tern (her hele)',
				amount: 0.11,
				unit: 'kg',
			},
			{
				name: 'Løg, hakkede (Hele zittauer)',
				amount: 0.39,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (her hele)',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.63,
				unit: 'kg',
			},
			{
				name: 'Tomatpuré',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Chiliflager',
				amount: 0.004,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.063,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.063,
				unit: 'kg',
			},
			{
				name: 'Letmælk',
				amount: 0.84,
				unit: 'kg',
			},
			{
				name: 'Vesterhavsost, revet',
				amount: 0.075,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, stødt',
				amount: 0.003,
				unit: 'kg',
			},
		]
	},
	{
		id: 'tomatosauceVeganCheese',
		ingredients: [
			{
				name: 'Hvide bønner, tørrede',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Knoldselleri, i tern (hel knoldselleri)',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Gulerødder, i tern (hele gulerødder)',
				amount: 0.26,
				unit: 'kg',
			},
			{
				name: 'Persillerødder, i tern (hele persillerødder',
				amount: 0.11,
				unit: 'kg',
			},
			{
				name: 'Løg, hakkede (hele zittauer)',
				amount: 0.39,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, hakkede (her hele hvidløg)',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Flåede tomater',
				amount: 0.63,
				unit: 'kg',
			},
			{
				name: 'Tomatpuré',
				amount: 0.02,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.008,
				unit: 'kg',
			},
			{
				name: 'Chiliflager',
				amount: 0.004,
				unit: 'kg',
			},
			{
				name: 'Rapsolie',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Ukogte kartofler, 40-60 mm',
				amount: 0.7,
				unit: 'kg',
			},
			{
				name: 'Kogevand (fra kartofler)',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Gærflager',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Løgpulver',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, presset (her hele hvidløg)',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Grøntsagsbouillon',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'LYS tahin (her alm. tahin)',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Dijonsennep',
				amount: 0.003,
				unit: 'kg',
			},
			{
				name: 'Æblecidereddike',
				amount: 0.03,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, revet',
				amount: 0.005,
				unit: 'kg',
			},
		]
	},
	{
		id: 'codSpinachTomatosauce',
		ingredients: [
			{
				name: 'Torskefisk, i tern',
				amount: 0.9,
				unit: 'kg',
			},
			{
				name: 'Porrer, skåret i ringe (hele)',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Hvidløg, finthakket (hele hvidløg)',
				amount: 0.01,
				unit: 'kg',
			},
			{
				name: 'Gulerødder, revet (hele gulerødder)',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Oregano',
				amount: 0.005,
				unit: 'kg',
			},
			{
				name: 'Tomater, flåede, dåse',
				amount: 1,
				unit: 'kg',
			},
			{
				name: 'Spinat, grofthakket',
				amount: 0.5,
				unit: 'kg',
			},
			{
				name: 'Olie, smagsneutral',
				amount: 0.05,
				unit: 'kg',
			},
			{
				name: 'Smør',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Hvedemel',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Sødmælk',
				amount: 1.5,
				unit: 'kg',
			},
			{
				name: 'Muskatnød, stødt',
				amount: 0.003,
				unit: 'kg',
			},
		]
	},
	{
		id: 'lasagnaPlates',
		ingredients: [
			{
				name: 'Lasagneplader, alm.',
				amount: 0.8,
				unit: 'kg',
			},
		]
	},
	{
		id: 'potatoSlices',
		ingredients: [
			{
				name: 'Kartofler, skiver (40-60 mm. rå)',
				amount: 2.5,
				unit: 'kg',
			},
		]
	},
	{
		id: 'aubergineSlices',
		ingredients: [
			{
				name: 'Aubergine, skiver',
				amount: 1.5,
				unit: 'kg',
			},
		]
	},
	{
		id: 'lasagnaPlatesWholeGrain',
		ingredients: [
			{
				name: 'Lasagne, fuldkorn',
				amount: 0.8,
				unit: 'kg',
			},
		]
	},
	{
		id: 'cauliflowerSlices',
		ingredients: [
			{
				name: 'Blomkål, skiver',
				amount: 2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'beanpastaScrews',
		ingredients: [
			{
				name: 'Mungbønnepasta, skruer',
				amount: 1,
				unit: 'kg',
			},
		]
	},
	{
		id: 'roastedMushrooms',
		ingredients: [
			{
				name: 'Mungbønnepasta, skruer',
				amount: 1.3,
				unit: 'kg',
			},
		]
	},
	{
		id: 'mozarella',
		ingredients: [
			{
				name: 'Mozzarella ost, 30+',
				amount: 0.2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'parmesanCheeseGrated',
		ingredients: [
			{
				name: 'Parmesanost, revet (Parmesan ost, 32+)',
				amount: 0.2,
				unit: 'kg',
			},
		]
	},
	{
		id: 'nutcrunchSage',
		ingredients: [
			{
				name: 'Hasselnødder, hakkede',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Mandler, hakkede',
				amount: 0.2,
				unit: 'kg',
			},
			{
				name: 'Salvie, hakket',
				amount: 0.015,
				unit: 'kg',
			},
		]
	},
	{
		id: 'spicyTomatoSalat',
		ingredients: [
			{
				name: 'Tomater, i forskellige størrelser og farver',
				amount: 0.95,
				unit: 'kg',
			},
			{
				name: 'Forårsløg, snittet',
				amount: 0.19,
				unit: 'kg',
			},
			{
				name: 'Chili, hakket, milde, røde',
				amount: 0.013,
				unit: 'kg',
			},
			{
				name: 'Sukker',
				amount: 0.072,
				unit: 'kg',
			},
			{
				name: 'Spinat, baby',
				amount: 0.15,
				unit: 'kg',
			},
			{
				name: 'Æblecidereddike',
				amount: 0.12,
				unit: 'kg',
			},
			{
				name: 'Olivenolie',
				amount: 0.143,
				unit: 'kg',
			},
		]
	},
]

export {
	ingredientDetails
};
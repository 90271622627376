
import { useEffect, useState } from 'react';
import firebaseInit from 'firebase-init';
import './app.scss';
import LandingPage from './landing-page/landing-page';
import Game from './game/game';
import Background from './background/background';
import ImageLoader from './ui/image-loader/image-loader';
import CookieHandler from './ui/cookie-handler/cookie-handler';

function App() {
	const [isGameStarted, setIsGameStarted] = useState(false);
	const [playerData, setPlayerData] = useState(null);
	const [lightsOn, setLightsOn] = useState(false);

	/**
	* Component mounted / will unmount
	*/
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});

	useEffect(() => {
		if (isGameStarted) {
			setLightsOn(false);
		} else {
			setLightsOn(true);
		}
	}, [isGameStarted]);
	
	return (
		<div className="App">
			<Background playerData={playerData} lightsOn={lightsOn}/>
			{isGameStarted ?
				<Game setPlayerData={setPlayerData} playerData={playerData} setLightsOn={setLightsOn}/>
				:
				<LandingPage startGame={() => {setIsGameStarted(true);}} />
			}
			<ImageLoader />
			<CookieHandler />
		</div>
	);
}

export default App;

import React from 'react';
import './choose-missions.scss';
import PropTypes from 'prop-types';
import Choice from 'components/ui/choice/choice';
import { missionData } from 'data/mission-data';
import Button from 'components/ui/button/button';
import { getText } from 'helpers/ui-text-helper';

const ChooseMissions = ({playerData, updatePlayerData, title, goToNextPage, goToPreviousPage}) => {
	/**
	 * Adds or removes mission, depending on whether mission is already chosen or not
	 * @param {string} missionId 
	 */
	 const handleToggleMission = (missionId) => {
		let newChosenMissionList = playerData.missions ? playerData.missions : null;
		// If we have no chosen missions yet, make a new list with the chosen mission
		if (!newChosenMissionList || newChosenMissionList.length === 0) {
			newChosenMissionList = [missionId];
		} else {
			// Check if mission is already in list
			const chosenMissionIndex = newChosenMissionList.findIndex((mission) => {
				return mission === missionId;
			});

			// If mission is not in list, add it, if it is, remove it.
			if (chosenMissionIndex === -1) {
				newChosenMissionList.push(missionId);
			} else {
				newChosenMissionList.splice(chosenMissionIndex, 1);
			}
		}

		// Update player data
		updatePlayerData({missions: [...newChosenMissionList], choseMissions: false});
	};

	/**
	 * Updates player data then continues to next page
	 */
	const handleGoToNextPage = () => {
		updatePlayerData({choseMissions: true});

		goToNextPage();
	};

	return (
		<div className='ChooseMissions'>
			<div className='ChooseMissions-title'>
				<span>{title}</span>
			</div>
			<div className='ChooseMissions-subTitle' dangerouslySetInnerHTML={
				{__html: getText('missionRules', 'gameRules')}
			}/>
			<div className='ChooseMissions-recipes'>
				{missionData.map((data, index) => {
					let isSelected = false;
					if (playerData && playerData.missions) {
						// Checking if mission is in list of chosen player missions
						isSelected = playerData.missions.findIndex((mission) => {
							return mission === data.id;
						}) !== -1;
					}

					return (
						<div key={index} className='ChooseMissions-recipeButton'>
							<Choice
								isSelected={isSelected}
								title={data.title}
								onClick={() => {handleToggleMission(data.id);}} 
								clickDelay={0}
								imageId={data.id}
								text={data.details}
							/>
						</div>
					);
				})}
			</div>
			<div className='ChooseMissions-navigation'>
				<Button
					text={getText('back', 'generalUi')}
					classes={['basicGradient', 'small', 'responsive', 'image', 'back', 'left']}
					onClick={() => {goToPreviousPage();}}
				/>
				<Button
					text={getText('continue', 'generalUi')}
					classes={['basicGradient', 'small', 'responsive', 'image', 'continue', 'right']}
					onClick={() => {handleGoToNextPage();}}
				/>
			</div>
		</div>
	);
};

ChooseMissions.propTypes = {
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	goToNextPage: PropTypes.func.isRequired,
	goToPreviousPage: PropTypes.func.isRequired,
};

export default ChooseMissions;
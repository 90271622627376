const pointCategories = [
	{
		id: 'taste',
		name: 'smag'
	},
	{
		id: 'co2',
		name: 'klimavenlighed'
	},
	{
		id: 'price',
		name: 'pris'
	},
	{
		id: 'protein',
		name: 'proteinkvalitet'
	},
	{
		// Combined should always be last in list
		id: 'combined',
		name: 'Samlet score'
	}
];

const proteinPointConversionArray = {
	sumArray: 		[1, 2, 3],
	resultArray: 	[4, 7, 10]
};

const xFactorPointConversionArray = {
	sumArray: 		[0, 1, 2, 3],
	resultArray: 	[0, 2, 3, 5]
};
const intensityPointConversionArray = {
	sumArray: 		[0, 1, 2, 3],
	resultArray: 	[0, 2, 3, 5]
};

const missionPointData = {
	missionLimit: 7,
	missionMultiplier: 1.5
};

const resultThresholds = [0, 16, 31, 51, 76];

module.exports = {
	pointCategories,
	proteinPointConversionArray,
	intensityPointConversionArray,
	xFactorPointConversionArray,
	missionPointData,
	resultThresholds,
};
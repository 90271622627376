import React, { useState } from 'react';
import './result-box.scss';
import PropTypes from 'prop-types';
import { getIngredientById } from 'helpers/ingredient-helper';
import { getText } from 'helpers/ui-text-helper';

const ResultBox = ({result, ingredientChoices}) => {
	const [resultHoverData, setResultHoverData] = useState(null);
	const [hasProteinBoost, setHasProteinBoost] = useState(false);
	let fillPercent = (result.value / 10) * 100;
	if (result.category.id === 'taste') {
		fillPercent = (result.value.total / 10) * 100;
	}

	// Packs and readies hover data for result info
	const handleShowResultHover = (category) => {
		let data = {
			category: category.id,
			values: []
		};

		switch (category.id) {
		case 'taste':
			data.values = [
				{
					id: 'balance',
					name: getText('balance', 'gameUi'),
					value: result.value.balance,
				},
				{
					id: 'tasteIntensity',
					name: getText('tasteIntensity', 'gameUi'),
					value: result.value.intensity,
				},
				{
					id: 'mouthfeel',
					name: getText('mouthfeel', 'gameUi'),
					value: result.value.texture,
				},
				{
					id: 'xFactor',
					name: getText('xFactor', 'gameUi'),
					value: result.value.xFactor,
				}
			];
			break;
		case 'co2':
		case 'price':
		case 'protein':
			let lastProtein = null;
			let hasProteinBoost = false;

			data.values.push({
				text: getText(category.id, 'result')
			});

			ingredientChoices.forEach((choice) => {
				const ingredient = getIngredientById(choice.ingredientId);
				const ingredientCategory = ingredient.values[category.id];

				if (ingredientCategory.value === '1X' || ingredientCategory.value === '1Y') {
					if (lastProtein && lastProtein !== ingredientCategory.value) {
						hasProteinBoost = true;
					} else {
						lastProtein = ingredientCategory.value;
					}
				}

				data.values.push({
					name: ingredient.name,
					value: ingredientCategory.value
				});
			});
			setHasProteinBoost(hasProteinBoost);
			break;
		case 'combined':
			data = null;
			break;
		default:
			break;
		}

		setResultHoverData(data);
	};

	return (
		<div className={'ResultBox ' + result.category.id}>
			{result.category.id !== 'combined' &&
				<div className='ResultBox-infoIcon' 
					onMouseEnter={() => {handleShowResultHover(result.category);}}
					onMouseOut={() => {setResultHoverData(null);}}/>
			}
			<div className={'ResultBox-title ' + result.category.id}>
				<span>{result.category.name}</span>
			</div>

			<div className='ResultBox-result'>
				<div className={'ResultBox-resultFill'}>
					<div className='ResultBox-fillReflection'/>
					<div className='ResultBox-fillBlock'
						style={{width: 100 - fillPercent + '%'}}/>
				</div>
				<span>{result.category.id === 'taste' ? result.value.total + '/10' : result.value + '/10'}</span>
			</div>

			{(resultHoverData && resultHoverData.category === result.category.id) &&
				<div className='ResultBox-pointResultPopupWrapper'>
					<div className='ResultBox-pointResultPopupArrow'/>
					<div className='ResultBox-pointResultPopup'>
						{resultHoverData.values.map((data, index) => {
							let classNameValue = data.value;

							if (hasProteinBoost && (data.value === '1Y' || data.value === '1X')) {
								classNameValue = 2;
							}

							const dataText = getText(data.id, 'result');

							return (
								<div key={index} className='ResultBox-pointResultPopupItemWrapper'>
									<div className='ResultBox-pointResultPopupItem'>
										{data.text && 
											<div className='ResultBox-pointResultItemText wide'>
												<span>{data.text}</span>
											</div>
										}
										{data.name &&
											<div className='ResultBox-pointResultPopupItemTitle'>
												<span>{data.name}</span>
											</div>
										}
										{dataText && 
											<div className='ResultBox-pointResultItemText'>
												<span>{dataText}</span>
											</div>
										}
									</div>
									{(data.value || data.value === 0) &&
										<div className={'ResultBox-pointResultPopupItemValue ' +
											result.category.id + '-' + classNameValue}/>
									}
								</div>
							);
						})}
					</div>
				</div>
			}
		</div>
	);
};

ResultBox.propTypes = {
	result: PropTypes.object.isRequired,
	ingredientChoices: PropTypes.array.isRequired,
};

export default ResultBox;
import React, { useEffect, useState } from 'react';
import './recipe-component-list.scss';
import PropTypes from 'prop-types';
import { getText } from 'helpers/ui-text-helper';
import RecipeComponent from '../recipe-component/recipe-component';
import { getRecipeFromId } from 'helpers/recipe-helper';
import { getIngredientById } from 'helpers/ingredient-helper';

const RecipeComponentList = ({handleUpdateCurrentComponent, currentComponent, playerData, isList}) => {
	const [recipeData, setRecipeData] = useState(null);

	useEffect(() => {
		const chosenRecipe = getRecipeFromId(playerData.recipeId);
		setRecipeData(chosenRecipe);
	}, [playerData.recipeId]);

	/**
	 * Checks if component choice exists in player data, and returns it if it does
	 * @param {string} componentId 
	 */
	const getComponentChoice = (componentId) => {
		// Player has made no choice yet
		if (!playerData.ingredientChoices) {
			return null;
		}

		return playerData.ingredientChoices.find((choice) => {
			return choice.componentId === componentId;
		});
	};

	return (
		<div className={'RecipeComponentList' + (isList ? ' isList' : '')} onClick={(e) => {e.stopPropagation();}}>
			{isList && 
				<div className='RecipeComponentList-recipeTitle'>
					<span>{getText('finishedRecipe', 'gameUi')}</span>
				</div>
			}
			{(recipeData && recipeData.componentLists) && recipeData.componentLists.map((component, index) => {
				const playerChoice = getComponentChoice(component.id);
				let ingredient = null;
				if (playerChoice) {
					ingredient = getIngredientById(playerChoice.ingredientId);
				}

				let className = playerData.recipeId +  ' position-' + index;
				if (isList) {
					className = 'isList';
				}

				let isSelected = false;
				if (currentComponent && currentComponent.id === component.id) {
					isSelected = true;
				}

				return (
					<RecipeComponent
						key={index}
						title={'Komponent ' + (index + 1) + (component.name ? ': ' + component.name : '')}
						handleUpdateCurrentComponent={() => {handleUpdateCurrentComponent(component);}}
						ingredient={ingredient}
						className={className}
						isSelected={isSelected}
					/>
				);
			})}
		</div>
	);
};

RecipeComponentList.propTypes = {
	handleUpdateCurrentComponent: PropTypes.func,
	currentComponent: PropTypes.object,
	playerData: PropTypes.object.isRequired,
	isList: PropTypes.bool.isRequired,
};

export default RecipeComponentList;
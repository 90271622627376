import React, { useEffect, useState } from 'react';
import './cooking.scss';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import { getText } from 'helpers/ui-text-helper';
import { pointCategories } from 'data/point-data';
import { getRecipeFromId } from 'helpers/recipe-helper';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RecipeDocument from 'components/pdf-styles/recipePdf';
import { 
	combinedResultCalculator, 
	generalPointCalculator, 
	getScalarList, 
	missionPointCalculator, 
	sumIngredients, 
	tasteSumCalculator 
} from 'helpers/point-helper';
import ResultBox from 'components/ui/result-box/result-box';
import IngredientChoice from 'components/ui/ingredient-choice/ingredient-choice';
import RecipeComponentList from 'components/ui/recipe-component-list/recipe-component-list';
import CombinedResult from 'components/ui/combined-result/combined-result';
import SavedDishes from 'components/ui/saved-dishes/saved-dishes';

const Cooking = ({updatePlayerData, playerData, title, goToPreviousPage, handleLoadRecipe, setShowInfoPopup}) => {
	const [recipeData, setRecipeData] = useState(getRecipeFromId(playerData.recipeId));
	const [currentComponent, setCurrentComponent] = useState(null);
	const [showResults, setShowResults] = useState(false);
	const [showIngredients, setShowIngredients] = useState(true);
	const [results, setResults] = useState(null);

	// Show scroll graphics or not
	const [showLeftScroll, setShowLeftScroll] = useState(true);
	const [showRightScroll, setShowRightScroll] = useState(true);

	// On init
	useEffect(() => {
		if (!playerData.notReset || !playerData.notReset.hasSeenGameInfo) {
			setShowInfoPopup(true);
		}

		const chosenRecipe = getRecipeFromId(playerData.recipeId);
		setRecipeData(chosenRecipe);

		if (playerData.showingResults) {
			const results = getResults();

			setResults(results);
			setShowResults(true);
			setShowIngredients(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerData]);

	// On currentComponent change
	useEffect(() => {
		if (currentComponent) {
			let ingredientChoice = null;
			if (playerData.ingredientChoices) {		
				ingredientChoice = playerData.ingredientChoices.find((choice) => {
					return choice.componentId === currentComponent.id;
				});
			}

			const listElement = document.getElementById('ingredientsChoices');
			if (listElement) {
				const listRect = listElement.getBoundingClientRect();

				if (ingredientChoice) {
					const ingredientElement = document.getElementById(ingredientChoice.ingredientId);

					if (ingredientElement) {
						const rect = ingredientElement.getBoundingClientRect();
						const rectCenter = rect.left + ((rect.right - rect.left) / 2);
						const listCenter = listRect.left + ((listRect.right - listRect.left) / 2);

						const scrollDiff = rectCenter - listCenter;

						listElement.scrollBy(
							{
								top: 0,
								left: scrollDiff,
								behavior: 'instant',
							}
						);
					}
				}

				const leftElement = document.getElementById(currentComponent.ingredients[0].id);
				const lastId = currentComponent.ingredients[currentComponent.ingredients.length - 1].id;
				const rightElement = document.getElementById(lastId);

				if (leftElement && rightElement) {
					setShowLeftScroll(leftElement.getBoundingClientRect().left < 0);
					setShowRightScroll(rightElement.getBoundingClientRect().right > window.innerWidth);
				}
			}
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentComponent]);

	/**
	 * Scrolls back and forth depending on given direction
	 * @param {number} direction 
	 */
	const handleIngredientScroll = (direction) => {
		const element = document.getElementById('ingredientsChoices');
		const elementWidth = element.clientWidth;
		// 25% of screen width as scroll setting
		const scrollAmount = elementWidth / 100 * 25 * direction;
		element.scrollBy(
			{
				top: 0,
				left: scrollAmount,
				behavior: 'smooth',
			});
		
		const leftElement = document.getElementById(currentComponent.ingredients[0].id);
		const lastId = currentComponent.ingredients[currentComponent.ingredients.length - 1].id;
		const rightElement = document.getElementById(lastId);

		if (leftElement && rightElement) {
			const leftRect = leftElement.getBoundingClientRect();
			const rightRect = rightElement.getBoundingClientRect();
			
			setShowLeftScroll(leftRect.left - scrollAmount <= 0);
			setShowRightScroll(rightRect.right - scrollAmount >= window.innerWidth);
		}
	};

	/**
	 * Handles updating currentComponent, if it is same component, then sets currentComponent to null.
	 * @param {obj} component 
	 */
	const handleUpdateCurrentComponent = (component) => {
		let newComponent = component;
		if (currentComponent && component.id === currentComponent.id) {
			newComponent = null;
		}

		setCurrentComponent(newComponent);
	};

	/**
	 * Handles getting and showing results
	 */
	const handleShowResults = () => {
		const results = getResults();
		setShowIngredients(false);

		handleLoadRecipe().then(() => {
			setResults(results);
			setShowResults(true);

			let savedDishes = [];
			if (playerData && playerData.savedDishes) {
				savedDishes = playerData.savedDishes;
			}

			const combinedResult = results.find((result) => {
				return result.category.id === 'combined';
			});

			savedDishes.push({
				recipeId: playerData.recipeId,
				ingredientChoices: [...playerData.ingredientChoices],
				combinedResult: combinedResult.value.total
			});
			
			updatePlayerData({showingResults: true, savedDishes: savedDishes, dishIndex: savedDishes.length - 1});
		});
	};

	/**
	 * Updates playerdata, with ingredient choice for component
	 * @param {obj} ingredient 
	 */
	const handleIngredientChoice = (ingredient) => {
		let currentIngredientChoices = [];
		const newIngredientChoice = {
			componentId: currentComponent.id,
			ingredientId: ingredient.id
		};

		if (playerData.ingredientChoices) {
			currentIngredientChoices = playerData.ingredientChoices;
		}
		
		// Check if current component already has an ingredient choice
		const choiceIndex = currentIngredientChoices.findIndex((ingredient) => {
			return ingredient.componentId === currentComponent.id;
		});

		// No choice for component
		if (choiceIndex === -1) {
			currentIngredientChoices.push(newIngredientChoice);
		} else {
			currentIngredientChoices[choiceIndex] = newIngredientChoice;
		}

		setCurrentComponent(null);

		updatePlayerData({ingredientChoices: currentIngredientChoices});
	};

	/**
	 * Checks if player has made choices for all recipe components
	 */
	const canCook = () => {
		if (!recipeData || !recipeData.componentLists || !playerData || !playerData.ingredientChoices) {
			return false;
		}

		return playerData.ingredientChoices.length === recipeData.componentLists.length;
	};

	/**
	 * Gets result for given category based on player choice
	 */
	const getResults = () => {
		const results = [];
		pointCategories.forEach((category) => {
			let scalarList = null;
			let result = 0;
			// Calculating results for taste
			if (category.id === 'taste') {
				result = tasteSumCalculator(playerData.ingredientChoices);
			// Calculating combined result
			} else if (category.id === 'combined') {
				result = combinedResultCalculator(results);
				if (playerData.missions) {
					result = missionPointCalculator(result, results, playerData.missions);
				}
			// Calculating everything else
			} else {
				const pointSum = sumIngredients(playerData.ingredientChoices, category.id);
				scalarList = getScalarList(recipeData, category.id);
				result = generalPointCalculator(pointSum, scalarList);
			}

			results.push({category: category, value: result});
		});

		return results;
	};

	/**
	 * Handles back button functionality. If showing results, saves current dish in saved dish list.
	 */
	const handleBackButton = () => {
		if (showResults) {
			updatePlayerData({showingResults: false});
			setShowIngredients(true);
			setShowResults(false);
		} else {
			updatePlayerData({ingredientChoices: [], showingResults: false});
			goToPreviousPage();
		}
	};

	return (
		<div className='Cooking'
			onClick={() => {
				if (currentComponent && !showResults) {
					setCurrentComponent(null);
				}
			}}>
			<div className='Cooking-recipeWrapper'>
				{recipeData &&
					<div className='Cooking-title'>
						<span>{title}</span>
					</div>
				}

				{/* RECIPE COMPONENTS */}
				{showIngredients &&
					<RecipeComponentList 
						handleUpdateCurrentComponent={handleUpdateCurrentComponent}
						currentComponent={currentComponent}
						playerData={playerData}
						isList={false}
					/>
				}

				{currentComponent &&
					<div className='Cooking-scrollNavigation'>
						{showRightScroll &&
							<div className='Cooking-scroll right' 
								onClick={(e) => {
									e.stopPropagation();
									handleIngredientScroll(1);
								}}
							/>
						}
						{showLeftScroll &&
							<div className='Cooking-scroll left' 
								onClick={(e) => {
									e.stopPropagation();
									handleIngredientScroll(-1);
								}}
							/>
						}
					</div>
				}

				{/* INGREDIENTS */}
				{(currentComponent && !showResults) &&
					<div className='Cooking-ingredientsChoicesWrapper'>
						<div className='Cooking-ingredientsGradient'/>
					
						<div id='ingredientsChoices' className='Cooking-ingredientsChoices'>
							{currentComponent.ingredients.map((ingredient, index) => {
								// Check if player has ingredient choice.
								let ingredientChoice = null;
								if (playerData.ingredientChoices) {		
									ingredientChoice = playerData.ingredientChoices.find((choice) => {
										return choice.componentId === currentComponent.id;
									});
								}
								
								// If we have an ingredient choice we mark the ingredient as selected
								let isSelected = false; 
								if (ingredientChoice) {
									isSelected = ingredientChoice.ingredientId === ingredient.id;
								}

								return (
									<div key={index} id={ingredient.id} className='Cooking-ingredient'>
										<IngredientChoice
											ingredientData={ingredient} 
											handleIngredientChoice={handleIngredientChoice}
											isSelected={isSelected}
										/>
									</div>
								);
							})}
						</div>
					</div>
				}
			</div>

			{showResults ?
				<div className='Cooking-resultScreen'>
					{/* RESULT OVERVIEW */}
					<div className='Cooking-resultScreenTitle'>
						<span>{getText('result', 'gameUi')}</span>
					</div>
					<div className='Cooking-pointCategories'>
						{results.map((result, index) => {
							return (
								<div key={index} className={'Cooking-pointCategory'}>
									{result.category.id === 'combined' ?
										<CombinedResult 
											result={result} 
											missions={playerData.missions}
										/>
										:
										<ResultBox 
											ingredientChoices={playerData.ingredientChoices} 
											result={result}
										/>
									}
								</div>
							);
						})}
					</div>
				</div>
				:
				<div className='Cooking-cookButton'>
					{!currentComponent &&
						<Button 
							text={getText('cookRecipe', 'gameUi')}
							isDisabled={!canCook() || !showIngredients}
							classes={['basicGradient', 'responsive', 'small']}
							onClick={() => {handleShowResults();}}
						/>
					}
				</div>
			}

			{(!currentComponent && ((!showResults && !playerData.savedDishes) || showResults)) &&
				<div className='Cooking-navigation'>
					<Button
						text={showResults ? getText('adjust', 'generalUi') : getText('back', 'generalUi')}
						classes={['basicGradient', 'small', 'responsive', 'image', 'back', 'left']}
						onClick={() => {handleBackButton();}}
					/>
				</div>
			}

			{(showResults && playerData && playerData.savedDishes && playerData.savedDishes.length > 1) &&
				<div className='Cooking-dishListWrapper'>
					<SavedDishes playerData={playerData} updatePlayerData={updatePlayerData}/>
				</div>
			}

			{showResults && 
				<div className='Cooking-pdfButton'>
					<PDFDownloadLink 
						document={<RecipeDocument playerData={playerData} results={results}/>} 
						fileName={
							getRecipeFromId(playerData.recipeId).name + '-' + (playerData.dishIndex + 1) + '.pdf'
						}>
						{({ blob, url, loading, error }) => {
							return (
								<Button
									text={getText('savePdf', 'gameUi')}
									isDisabled={loading}
									classes={['basicGradient', 'responsive', 'small']}
									onClick={() => {}}
								/>
							);
						}}
					</PDFDownloadLink>
				</div>
			}
		</div>
	);
};

Cooking.propTypes = {
	updatePlayerData: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	goToPreviousPage: PropTypes.func.isRequired,
	handleLoadRecipe: PropTypes.func.isRequired,
	setShowInfoPopup: PropTypes.func.isRequired,
};

export default Cooking;
import React, { useEffect, useState } from 'react';
import './cookie-handler.scss';
import { getText } from 'helpers/ui-text-helper';
import Button from '../button/button';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookie-helper';
import appConfig from 'config/app.config';

const CookieHandler = () => {
	const [isCookiePopupActive, setIsCookiePopupActive] = useState(false);

	useEffect(() => {
		if (!getCookie(appConfig.cookieConsentName)) {
			setIsCookiePopupActive(true);
		}
	}, []);

	const toggleCookiePopup = () => {
		setIsCookiePopupActive(!isCookiePopupActive);
	};

	const handleCookieDecision = (accepted) => {
		toggleCookiePopup();

		if (accepted) {
			setCookie(appConfig.cookieConsentName, 'ok');
		} else {
			deleteCookie(appConfig.cookiePlayerData);
			deleteCookie(appConfig.cookieConsentName);
		}
	};

	return (
		<div className='CookieHandler'>
			{isCookiePopupActive &&
				<div className='CookieHandler-overlay'>
					<div className='CookieHandler-dialogue'>
						<div className='CookieHandler-dialogueText'
							dangerouslySetInnerHTML={{__html: getText('cookieText', 'cookie')}}/>
						<div className='CookieHandler-dialogueButtons'>
							<div className={'CookieHandler-declineButton'}>
								<Button
									text={'Nej'}
									classes={[
										'basicGradient',
										'responsive',
										'small',
										'red',
										'capitalize',
										'bold',
										'textSizeLarge'
									]}
									onClick={() => {handleCookieDecision(false);}}
								/>
							</div>
							<Button
								text={'Ja'}
								classes={[
									'basicGradient',
									'responsive',
									'small',
									'capitalize',
									'bold',
									'textSizeLarge'
								]}
								onClick={() => {handleCookieDecision(true);}}
							/>
						</div>
					</div>
				</div>
			}

			<div className={'CookieHandler-button ' + (isCookiePopupActive ? 'active' : '')}
				onClick={() => {toggleCookiePopup();}}
			/>
		</div>
	);
};

export default CookieHandler;
const backgrounds = [
	'background/frikadeller.svg',
	'background/karry.svg',
	'background/kitchen.svg',
	'background/lasagne.svg',
	'background/overlay.svg',
	'background/plate.svg',
	'background/table.svg',
];

const icons = [
	'icons/icon-arrow.svg',
	'icons/icon-arrow-2.svg',
	'icons/icon-back.svg',
	'icons/icon-badgeCo2.svg',
	'icons/icon-badgePrice.svg',
	'icons/icon-badgeTaste.svg',
	'icons/icon-cheap.svg',
	'icons/icon-check.svg',
	'icons/icon-check2.svg',
	'icons/icon-climate.svg',
	'icons/icon-close.svg',
	'icons/icon-continue.svg',
	'icons/icon-cross.svg',
	'icons/icon-curry.svg',
	'icons/icon-i.svg',
	'icons/icon-info-2.svg',
	'icons/icon-info-3.svg',
	'icons/icon-info.svg',
	'icons/icon-lasagna.svg',
	'icons/icon-loading.svg',
	'icons/icon-meatballs.svg',
	'icons/icon-smiley-glad.svg',
	'icons/icon-smiley-gladdest.svg',
	'icons/icon-smiley-sad.svg',
	'icons/icon-smiley-saddest.svg',
	'icons/icon-smiley.svg',
	'icons/icon-tasty.svg',
];

const ingredients = [
	// ESTRAS
	'ingredients/extras/cottageCheese.svg',
	'ingredients/extras/mangoChutney.svg',
	'ingredients/extras/pickledAsier.svg',
	'ingredients/extras/pickledLingonberries.svg',
	'ingredients/extras/pumpkinMarmalade.svg',

	// GREENS
	'ingredients/greens/bananaSalat.svg',
	'ingredients/greens/butterBrusselSprouts.svg',
	'ingredients/greens/cucumberSalat.svg',
	'ingredients/greens/friedMushrooms.svg',
	'ingredients/greens/haricotSteamed.svg',
	'ingredients/greens/ovenbakedCarrots.svg',
	'ingredients/greens/pickledRedCabbage.svg',
	'ingredients/greens/rawCarrotNutSalat.svg',
	'ingredients/greens/romaneSteamed.svg',
	'ingredients/greens/winterkaleApple.svg',

	// MEATBALLS
	'ingredients/meatballs/choppedChicken.svg',
	'ingredients/meatballs/choppedCow.svg',
	'ingredients/meatballs/choppedFish.svg',
	'ingredients/meatballs/choppedLamb.svg',
	'ingredients/meatballs/choppedPig.svg',
	'ingredients/meatballs/choppedPigBeans.svg',
	'ingredients/meatballs/choppedPigPeas.svg',
	'ingredients/meatballs/choppedQuinoaChickpea.svg',

	// PLATES
	'ingredients/plates/aubergineSlices.svg',
	'ingredients/plates/cauliflowerSlices.svg',
	'ingredients/plates/lasagnaPlates.svg',
	'ingredients/plates/lasagnaPlatesWholeGrain.svg',
	'ingredients/plates/potatoSlices.svg',
	'ingredients/plates/roastedMushrooms.svg',

	// SAUCE
	'ingredients/sauce/remouladeSauce.svg',
	'ingredients/sauce/sennepCreme.svg',
	'ingredients/sauce/skysauce.svg',
	'ingredients/sauce/stewedCabbageSpinach.svg',
	'ingredients/sauce/tomatoSauceParmasan.svg',

	// SIDES
	'ingredients/sides/beanpasta-screws.svg',
	'ingredients/sides/butterRiceApricot.svg',
	'ingredients/sides/eggnoodles.svg',
	'ingredients/sides/lenses-kale.svg',
	'ingredients/sides/mintyoghurtAlmondsCucumberTomato.svg',
	'ingredients/sides/pasta-strips.svg',
	'ingredients/sides/potato-boiled.svg',
	'ingredients/sides/potatoboats.svg',
	'ingredients/sides/rice-parboiled.svg',
	'ingredients/sides/riceBrown.svg',
	'ingredients/sides/spicyTomatoSalat.svg',

	// STEW
	'ingredients/stew/bologneseBechamelCow.svg',
	'ingredients/stew/bologneseBechamelSquashCow.svg',
	'ingredients/stew/bolognesePeaProtein.svg',
	'ingredients/stew/cabbageBaconApple.svg',
	'ingredients/stew/chickenCurry.svg',
	'ingredients/stew/codSpinachTomatosauce.svg',
	'ingredients/stew/curryChickpeaCauliflowerPig.svg',
	'ingredients/stew/curryPeaCauliflower.svg',
	'ingredients/stew/daalGreenLenses.svg',
	'ingredients/stew/fishCurryCoconutSauce.svg',
	'ingredients/stew/tomatosauceVeganCheese.svg',
	'ingredients/stew/tomatosauceVeggieBechamel.svg',

	// TOPPINGS
	'ingredients/toppings/coconutFlakes.svg',
	'ingredients/toppings/dryMango.svg',
	'ingredients/toppings/freshBanana.svg',
	'ingredients/toppings/honeyroastedSunflowerseeds.svg',
	'ingredients/toppings/mozarella.svg',
	'ingredients/toppings/nutcrunchSage.svg',
	'ingredients/toppings/parmesanCheeseGrated.svg',
	'ingredients/toppings/peanuts.svg',
	'ingredients/toppings/raisins.svg',
];

const images = [
	'dialogueArrowDown.svg',
	'dialogueArrowLeft.svg',
	'dialogueArrowRight.svg'
];

const logos = [
	'logos/logo.svg',
	'logos/logo-absalon.svg',
	'logos/logo-gamelab.svg',
];

const imagesData = [
	// BACKGROUND
	...backgrounds,

	// ICONS
	...icons,

	// INGREDIENTS
	...ingredients,

	// LOGOS
	...logos,

	// IMAGES
	...images,
];

export {
	imagesData
};

import React, { useEffect } from 'react';
import './choice.scss';
import PropTypes from 'prop-types';

const Choice = ({isSelected, title, onClick, clickDelay, imageId, text}) => {
	const timeout = null;

	const handleOnClick = () => {
		setTimeout(() => {
			onClick();
		}, clickDelay);
	};

	useEffect(() => {
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	});

	return (
		<div className='Choice' onClick={() => {handleOnClick();}}>
			<div className={'Choice-wrapper' + (isSelected ? ' clicked' : '')}>
				<div className='Choice-title'>
					{isSelected &&
						<div className='Choice-checkmark'/>
					}
					<span>{title}</span>
				</div>
				<div className='Choice-content'>
					{text && 
						<div className='Choice-contentText'>
							<span>{text}</span>
						</div>
					}
					<div className={'Choice-contentImage ' + imageId}/>
				</div>
			</div>
		</div>
	);
};

Choice.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	clickDelay: PropTypes.number.isRequired,
	imageId: PropTypes.string.isRequired,
	text: PropTypes.string,
};

export default Choice;
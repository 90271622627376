import React from 'react';
import './background.scss';
import PropTypes from 'prop-types';
import Plate from 'components/ui/plate/plate';
import {ReactComponent as Kitchen} from 'assets/images/background/kitchen.svg';
import Particles from 'components/ui/particles/particles';

const Background = ({playerData, lightsOn}) => {
	
	return (
		<div className='Background'>
			<div className='Background-kitchen'>
				<Kitchen className={'Background-kitchenSvg ' + (lightsOn ? 'lightsOn' : '')}/>
				<div className={'Background-steam pos-1 ' + (lightsOn ? 'lightsOn' : '')}>
					<Particles particleAmount={200} spread={30}/>
				</div>
				<div className={'Background-steam pos-2 ' + (lightsOn ? 'lightsOn' : '')}>
					<Particles particleAmount={200} spread={45}/>
				</div>
			</div>
			<div className='Background-overlay'/>
			<div className='Background-table'>
				<div className='Background-plate'>
					<Plate playerData={playerData}/>
				</div>
			</div>
		</div>
	);

};

Background.propTypes = {
	playerData: PropTypes.object,
	lightsOn: PropTypes.bool.isRequired
};

export default Background;
import React, { useState } from 'react';
import './recipe-component.scss';
import PropTypes from 'prop-types';
import { getText } from 'helpers/ui-text-helper';
import IngredientInfobox from '../ingredient-infobox/ingredient-infobox';

const RecipeComponent = ({handleUpdateCurrentComponent, ingredient, title, className, isSelected}) => {
	const [isHoveringIngredient, setIsHoveringIngredient] = useState(false);

	return (
		<div key={className} className={'RecipeComponent ' + className}>	
			{isSelected && 
				<div className={'RecipeComponent-recipeLine ' + className}/>
			}

			<div className={'RecipeComponent-wrapper ' + className + (isSelected ? ' selected' : '')}
				onClick={() => {
					if (className !== 'isList' && handleUpdateCurrentComponent) {
						handleUpdateCurrentComponent();
					}
				}}>
				{(ingredient && className !== 'isList') && 
					<div className='RecipeComponent-checkmark'/>
				}
				<div className='RecipeComponent-title'>
					<span>{title}</span>
				</div>
				<div className='RecipeComponent-choice'>
					<span>{ingredient ? ingredient.name : getText('choose2', 'gameUi')}</span>
				</div>
			</div>

			{className === 'isList' &&
				<div className='RecipeComponent-infoIcon'
					onMouseEnter={() => {setIsHoveringIngredient(true);}}
					onMouseLeave={() => {setIsHoveringIngredient(false);}}/>
			}

			{isHoveringIngredient &&
				<div className='RecipeComponent-infoBox'>
					<IngredientInfobox ingredientData={ingredient} className={className}/>
				</div>
			}
		</div>
	);
};

RecipeComponent.propTypes = {
	handleUpdateCurrentComponent: PropTypes.func,
	ingredient: PropTypes.object,
	title: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
};

export default RecipeComponent;
import iconI from '../assets/images/icons/icon-i.svg';

const infoText = {
	centered: [
		{
			text: `Klik på logoet i midten af skærmen hvis du vil resette og starte forfra.`,
			style: ['bold']
		}
	],
	leftMargin: [
		{
			text: `Vælg de komponenter, som skal være i din ret. Tryk så på “tilbered” ret for at se hvor højt den scorer.<br /><br />
			Hver komponent har en værdi for hvor klimavenlig den er, hvor god prisen er og hvor god proteinkvalitet, den har. Det vises med farvekoder. 
			Desuden har komponenterne en række nøgleord, som beskriver smag. 
			Når du skal vælge komponenter kan du sætte musen på det lille !IMAGE! for at få mere info.`,
			hasImage: true,
		},
		{
			title: 'Klimavenlighed',
			text: `Handler om hvor stort CO2-aftryk komponenten har. Scoren fra værst til bedst vises med følgende farver:`,
			colorInfo: [
				'red', 'orange', 'yellow', 'green', 'greenest'
			]
		},
		{
			title: 'Pris',
			text: `Viser hvor god prisen er på komponenten. Vises fra værst til bedst med følgende farver:`,
			colorInfo: [
				'red', 'yellow', 'greenest'
			]
		},
	],
	
	rightMargin: [
		{
			title: 'Proteinkvalitet',
			text: `Viser hvor god proteinkvaliteten er i komponenten.<br /> Det handler altså IKKE om mængden af protein, men om  aminosyresammensætning. <br />Vises fra værst til bedst med følgende farver:`,
			colorInfo: [
				'red', 'yellow', 'greenest'
			]
		},
		{
			title: 'Smag',
			text: `Rettens samlede score i smag afhænger af, om der er balance i grundsmagene, om retten har en intens smag, om den har god mundfølelse og om den har x-factor (fx ved at den trigeminale sans stimuleres). <br />Når retten er tilberedt vises scoren i hver underkategori fra værst til bedste med følgende farver:`,
			colorInfo: [
				'red', 'orange', 'yellow', 'green', 'greenest'
			]
		},
		{
			text: `Hver komponent har et eller flere nøgleord til at beskrive smag, og deres samspil giver et resultat i smag. Nøgleordene er:<br />
			Umami, sød, cremet, bitter, sur, melet, kraftfuld smag, fast, elastisk, saftig, grynet, tør, klæg, blød, sprød, varmende, kølende, salt.`
		}
	]
};

export {
	infoText
};
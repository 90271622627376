import React, { useEffect, useState } from 'react';
import './game.scss';
import { gamePagesData } from 'data/game-pages-data';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookie-helper';
import appConfig from 'config/app.config';
import ChoiceInfo from 'components/ui/choice-info/choice-info';
import GameLogo from 'components/ui/game-logo/game-logo';
import RecipeComponentList from 'components/ui/recipe-component-list/recipe-component-list';
import PropTypes from 'prop-types';
import InfoPopup from 'components/ui/info-popup/info-popup';
import ResetPopup from 'components/ui/reset-popup/reset-popup';

const Game = ({playerData, setPlayerData, setLightsOn}) => {
	const [loadTimeout, setLoadTimeout] = useState(null);
	const [showInfoPopup, setShowInfoPopup] = useState(false);
	const [showResetPopup, setShowResetPopup] = useState(false);

	// Component control
	const [pageId, setPageId] = useState('chooseRecipe');
	const pageData = gamePagesData[pageId];
	const nextPage = pageData.nextPage;
	const previousPage = pageData.previousPage;

	let CurrentComponent = pageData.component;

	useEffect(() => {
		let newPlayerData = getCookie(appConfig.cookiePlayerData);
		if (newPlayerData && newPlayerData.length > 0) {
			newPlayerData = Object.assign({}, {...playerData, ...JSON.parse(newPlayerData)});
		}
		updatePlayerData(newPlayerData);

		// Player has made a choice about recipe already
		if (newPlayerData.recipeId) {
			// Player has made a choice about missions already
			if (newPlayerData.choseMissions) {
				setPageId('cooking');
			} else if (newPlayerData.showingResults) {
				// No mission choice has been made
				setPageId('chooseMission');
			}
		}
		
		return () => {
			if (loadTimeout) {
				clearTimeout(loadTimeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Updates player data
	 * @param {Object} updates 
	 * @param {bool} onlyUpdates used when you want to ignore state and only use updates. This resets playerdata. 
	 */
	const updatePlayerData = (updates, onlyUpdates = false) => {
		// Temporary functionality, placeholder for cookies
		let newPlayerData;
		if (onlyUpdates) {
			newPlayerData = Object.assign({}, {...updates});
		} else {
			newPlayerData = Object.assign({}, {...playerData, ...updates});
		}
		if (getCookie(appConfig.cookieConsentName)) {
			setCookie(appConfig.cookiePlayerData, JSON.stringify(newPlayerData));
		}
		setPlayerData(newPlayerData);
	};

	/**
	 * Deletes game cookie and resets game page
	 */
	const resetGame = () => {
		// Save player data that is not supposed to be reset
		const notReset = playerData.notReset;
		deleteCookie(appConfig.cookiePlayerData);

		// Reapply not resettet player data
		updatePlayerData({notReset: notReset}, true);
		setPageId('chooseRecipe');
	};

	/**
	 * Goes to next page using pageData
	 */
	const goToNextPage = () => {
		setPageId(nextPage);
	};

	/**
	 * Sets a random load timer between min and max load timer.
	 */
	const handleLoadRecipe = () => {
		return new Promise((resolve) => {
			const loadTime = Math.random() * 
				(appConfig.recipeLoadTimeMax - appConfig.recipeLoadTimeMin) +
				appConfig.recipeLoadTimeMin;
			
			setLightsOn(true);

			const timeout = setTimeout(() => {
				setLightsOn(false);
				resolve();
			}, 1000 * loadTime);

			setLoadTimeout(timeout);
		});
	};

	/**
	 * Goes to the previous page using pageData
	 */
	const goToPreviousPage = () => {
		setPageId(previousPage);
	};

	return (
		<div className='Game'>

			<div className='Game-leftUi'>
				{pageData.showChoiceInfo &&
					<ChoiceInfo
						playerData={playerData}
					/>
				}
				{playerData && playerData.showingResults &&
					<RecipeComponentList 
						playerData={playerData}
						isList={true}
					/>
				}
			</div>
			{CurrentComponent &&
				<div className='Game-component'>
					<GameLogo openResetPopup={() => {setShowResetPopup(true);}}/>
					<CurrentComponent 
						updatePlayerData={updatePlayerData}
						playerData={playerData}
						goToNextPage={goToNextPage}
						goToPreviousPage={goToPreviousPage}
						title={pageData.title}
						resetGame={resetGame}
						handleLoadRecipe={handleLoadRecipe}
						setShowInfoPopup={setShowInfoPopup}
					/>
				</div>
			}
			
			{playerData && !playerData.showingResults &&
				<div className='Game-infoIcon' onClick={() => {setShowInfoPopup(true);}}/>
			}
			{(showInfoPopup && (playerData && !playerData.showingResults)) &&
				<InfoPopup updatePlayerData={updatePlayerData} closePopup={() => {setShowInfoPopup(false);}}/>
			}
			{showResetPopup &&
				<ResetPopup closePopup={() => {setShowResetPopup(false);}} resetGame={resetGame}/>
			}
		</div>
	);
};

Game.propTypes = {
	playerData: PropTypes.object,
	setPlayerData: PropTypes.func.isRequired,
	setLightsOn: PropTypes.func.isRequired,
};

export default Game;
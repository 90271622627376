import React, { useEffect, useState } from 'react';
import './plate.scss';
import PropTypes from 'prop-types';
import {ReactComponent as EmptyPlate} from 'assets/images/background/plate.svg';
import {ReactComponent as Frikadeller} from 'assets/images/background/frikadeller.svg';
import {ReactComponent as Curry} from 'assets/images/background/karry.svg';
import {ReactComponent as Lasagna} from 'assets/images/background/lasagne.svg';
import { getText } from 'helpers/ui-text-helper';

const Plate = ({playerData, sizeClass = ''}) => {
	const [CurrentDish, setCurrentDish] = useState(null);
	const [recipe, setRecipe] = useState(null);
	const [ingredients, setIngredients] = useState('');
	
	useEffect(() => {
		if (!playerData || !playerData.recipeId) {
			setCurrentDish(EmptyPlate);
			return;
		}

		if (playerData.recipeId) {
			setRecipe(playerData.recipeId);

			switch (playerData.recipeId) {
			case 'meatBalls':
				setCurrentDish(Frikadeller);
				break;
			case 'curry':
				setCurrentDish(Curry);
				break;
			case 'lasagna':
				setCurrentDish(Lasagna);
				break;
			default:
				setCurrentDish(EmptyPlate);
				console.error(getText('unknownRecipeId', 'errorTexts') + playerData.recipeId);
				break;
			}
			let ingredientList = '';
			if (playerData && playerData.ingredientChoices) {
				playerData.ingredientChoices.forEach((choice) => {
					ingredientList += ' ' + choice.ingredientId;
				});
			}
			setIngredients(ingredientList);
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerData]);

	return (
		<div className={'Plate ' + sizeClass}>
			{CurrentDish &&
				<CurrentDish className={'Plate ' + (recipe ? recipe : '') + ingredients + ' ' + sizeClass}/>
			}
		</div>
	);
};

Plate.propTypes = {
	playerData: PropTypes.object,
	sizeClass: PropTypes.string,
};

export default Plate;
import { ingredientDetails } from 'data/ingredient-detail-data';
import { ingredients } from 'data/ingredients-data';
import { valuesTemplate } from 'data/templates/values-template';

/**
 * Gets ingredient data by id
 * @param {string} ingredientId 
 * @returns object
 */
export function getIngredientById(ingredientId) {
	const ingredient = ingredients[ingredientId];
	
	return ingredient;
};

/**
 * Builds ingredient object
 * @param {string} id 
 * @param {string} name 
 * @param {array} keywords 
 * @param {object} values 
 * @returns object
 */
export function buildIngredient(id, name, keywords, values = {price: 0, protein: 0, co2: 0}) {
	const template = JSON.parse(JSON.stringify(valuesTemplate));

	const ingredient = {
		id: id,
		name: name,
		keywords: keywords,
		values: template
	};

	ingredient.values.price.value = values.price;
	ingredient.values.protein.value = values.protein;
	ingredient.values.co2.value = values.co2;

	return ingredient;
};

/**
 * Gets ingredient details by ingredient and recipe ids
 * @param {string} ingredientId 
 * @param {string} recipeId 
 * @returns 
 */
export function getIngredientDetailsById(ingredientId) {
	const result = ingredientDetails.find((details) => {
		return details.id === ingredientId;
	});

	if (!result) {
		return null;
	}

	return result;
};
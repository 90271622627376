import React from 'react';
import './reset-popup.scss';
import PropTypes from 'prop-types';
import Button from '../button/button';
import { getText } from 'helpers/ui-text-helper';

const ResetPopup = ({closePopup, resetGame}) => {
	return (
		<div className='ResetPopup'>
			<div className='ResetPopup-dialogue'>
				<div className='ResetPopup-dialogueText'>
					<span>{getText('resetPopupText', 'gameUi')}</span>
				</div>
				<div className='ResetPopup-dialogueNavigation'>
					<Button
						text={getText('cancel', 'generalUi')}
						classes={['basicGradient', 'small', 'short', 'responsive', 'red']}
						onClick={() => {closePopup();}}
					/>
					<Button
						text={getText('confirm', 'generalUi')}
						classes={['basicGradient', 'small', 'short', 'responsive']}
						onClick={() => {resetGame(); closePopup();}}
					/>
				</div>
			</div>
		</div>
	);
};

ResetPopup.propTypes = {
	closePopup: PropTypes.func.isRequired,
	resetGame: PropTypes.func.isRequired,
};

export default ResetPopup;
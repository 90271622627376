/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyD6yya758r1B5EdHJMlnP2l3dAV5BIsAoA',
		authDomain: 'cgl-absalon-test.firebaseapp.com',
		projectId: 'cgl-absalon-test',
		storageBucket: 'cgl-absalon-test.appspot.com',
		messagingSenderId: '309294922625',
		appId: '1:309294922625:web:4b42b7ef7634fd7f3e0680'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyB6U9aGb0qKAW8_QYji7JZ825LgCLAeTv0',
		authDomain: 'cgl-absalon-production.firebaseapp.com',
		projectId: 'cgl-absalon-production',
		storageBucket: 'cgl-absalon-production.appspot.com',
		messagingSenderId: '1045247315452',
		appId: '1:1045247315452:web:31ba6a79563e1e82a2f679'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;
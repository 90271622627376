import {
	intensityPointConversionArray, 
	missionPointData, 
	proteinPointConversionArray, 
	resultThresholds, 
	xFactorPointConversionArray
} from 'data/point-data';
import { getIngredientById } from './ingredient-helper';
import { getMissionFromId } from './mission-helper';
import { getText } from './ui-text-helper';

export function getScalarList (recipeData, categoryId) {
	switch (categoryId) {
	case 'price':
		return recipeData.pointArrays.price;
	case 'co2':
		return recipeData.pointArrays.co2;
	case 'protein':
		return proteinPointConversionArray;
	case 'taste':
		break;
	case 'combined':
		break;
	default:
		console.error(getText('unknownIngredientCategory', 'errorTexts') + ': ' + categoryId);
		break;
	}
};

/**
 * Converts protein points to a 1-3 scale, based on ingredient choice
 * 2 points if ingredients with 1x and 1y protiens, or just the highest ingredient value.
 * @param {Array} ingredients 
 */
export function proteinSumCalculator (ingredients) {
	let hasX = false;
	let hasY = false;
	let result = 0;
	
	ingredients.forEach((ingredient) => {
		const ingredientData = getIngredientById(ingredient.ingredientId);
		const proteinData = ingredientData.values.protein;
		if (proteinData.value === '1X') {
			hasX = true;
		} else if (proteinData.value === '1Y') {
			hasY = true;
		} else if (proteinData.value > result) {
			result = proteinData.value;
		}
	});

	if (hasX && hasY) {
		result = 2;
	}

	return result;
};

/**
 * Calculates combined point result
 * @param {array} otherResults 
 * @returns number
 */
export function combinedResultCalculator (otherResults) {
	let pointSum = 0;
	let pointResult = 0;

	otherResults.forEach((result) => {
		if (result.value.total) {
			pointSum += result.value.total;
		} else {
			pointSum += result.value;
		}
	});

	pointResult = pointSum;

	return pointResult;
};

/**
 * Applies completed missions to combined result points, based on mission multiplier
 * Rounds final result to nearest whole number
 * @param {int} combinedResult 
 * @param {array} results 
 * @param {array} missions 
 * @returns int
 */
export function missionPointCalculator (combinedResult, results, missions) {
	let finalCombinedResult = combinedResult;
	const completedMissions = [];
	missions.forEach((mission) => {
		const missionData = getMissionFromId(mission);
		const pointData = results.find((result) => {
			return result.category.id === missionData.categoryId;
		});
		let categoryPoints = 0;

		if (pointData) {
			if (pointData.value.total) {
				categoryPoints = pointData.value.total;
			} else {
				categoryPoints = pointData.value;
			}
		}

		if (categoryPoints >= missionPointData.missionLimit) {
			// The mission bonus is the added difference for the categorypoints after multiplier
			const missionBonus = (categoryPoints * missionPointData.missionMultiplier - categoryPoints);
			finalCombinedResult = finalCombinedResult + missionBonus;
			completedMissions.push(mission);
		}
	});

	return {
		total: Math.round(finalCombinedResult),
		completedMissions: completedMissions
	};
};

/**
 * Combined taste results, based on chosen ingredients and keywords
 * @param {array} ingredients 
 * @returns 
 */
export function tasteSumCalculator (ingredients) {
	// Balance
	let balanceSum = 5;
	let saltCount = 0;
	let bitterCount = 0;
	let sourCount = 0;
	let sweetCount = 0;
	let umamiCount = 0;
	// Intensity
	let intensitySum = 0;
	let intensityCount = 0;
	// Texture
	let textureSum = 4;
	let isTough = false;
	let isFloury = false;
	let isDry = false;
	let isFirm = false;
	let isSticky = false;
	let badTextureCount = 0;
	let isCreamy = false;
	let isSoft = false;
	let crispyCount = 0;
	// xFactor
	let xFactorCount = 0;
	let xFactorSum = 0;
	
	ingredients.forEach((ingredient) => {
		const ingredientData = getIngredientById(ingredient.ingredientId);
		ingredientData.keywords.forEach((keyword) => {
			// Counting for balance
			if (keyword.id === 'sour') {
				sourCount ++;
			} else if (keyword.id === 'salty') {
				saltCount ++;
			} else if (keyword.id === 'bitter') {
				bitterCount ++;
			} else if (keyword.id === 'sweet') {
				sweetCount ++;
			} else if (keyword.id === 'umami') {
				umamiCount ++;
			} else if (keyword.id === 'powerful') {
			// Counting for intensity
				intensityCount ++;
			} else if (keyword.id === 'tough' && !isTough) {
			// Checking for texture
				isTough = true;
				badTextureCount ++;
			} else if (keyword.id === 'floury' && !isFloury) {
				isFloury = true;
				badTextureCount ++;
			} else if (keyword.id === 'dry' && !isDry) {
				isDry = true;
				badTextureCount ++;
			} else if (keyword.id === 'firm' && !isFirm) {
				isFirm = true;
				badTextureCount ++;
			} else if (keyword.id === 'sticky' && !isSticky) {
				isSticky = true;
				badTextureCount ++;
			} else if (keyword.id === 'creamy') {
				isCreamy = true;
			} else if (keyword.id === 'soft') {
				isSoft = true;
			} else if (keyword.id === 'crispy') {
				crispyCount ++;
			}
			// Counting for xFactor
			if (keyword.isXFactor) {
				xFactorCount ++;
			}
		});
	});

	// Calculating for balance
	if (saltCount >= 2) {
		balanceSum += -1;
	}
	if (bitterCount === 0 || bitterCount >= 3) {
		balanceSum += -1;
	}
	if (sourCount === 0 || sourCount >= 3) {
		balanceSum += -1;
	}
	if (sweetCount === 0 || sweetCount >= 4) {
		balanceSum += -1;
	}
	if (umamiCount === 0) {
		balanceSum += -1;
	}

	// Minimum value of balance is 0
	if (balanceSum < 0) {
		balanceSum = 0;
	}

	// Calculating for intensity
	if (intensityCount > intensityPointConversionArray.resultArray.length - 1) {
		intensitySum = intensityPointConversionArray.resultArray[intensityPointConversionArray.resultArray.length - 1];
	} else {
		intensitySum = intensityPointConversionArray.resultArray[intensityCount];
	}
	// Calculating for texture
	if (badTextureCount > 3) {
		textureSum -= 3;
	} else {
		textureSum -= badTextureCount;
	}
	if (!isCreamy && !isSoft) {
		textureSum --;
	}
	if (crispyCount > 2) {
		textureSum += 2;
	} else {
		textureSum += crispyCount;
	}
	// Calculating for xFactor
	if (xFactorCount > xFactorPointConversionArray.resultArray.length - 1) {
		xFactorSum = xFactorPointConversionArray.resultArray[xFactorPointConversionArray.resultArray.length - 1];
	} else {
		xFactorSum = xFactorPointConversionArray.resultArray[xFactorCount];
	}

	const total = Math.ceil(((textureSum + balanceSum + intensitySum + xFactorSum) / 2));

	return {
		texture: textureSum, 
		balance: balanceSum, 
		intensity: intensitySum, 
		xFactor: xFactorSum,
		total: total};
};

/**
 * Sums ingredient values together based on valueId
 * @param {array} ingredients 
 * @param {string} valueId 
 */
export function sumIngredients (ingredients, categoryId) {
	let sum = 0;

	switch (categoryId) {
	case 'price':
	case 'co2':
		ingredients.forEach((ingredient) => {
			const ingredientData = getIngredientById(ingredient.ingredientId);
			sum += ingredientData.values[categoryId].value;
		});
		break;
	case 'protein':
		sum = proteinSumCalculator(ingredients);
		break;
	case 'taste':
		sum = tasteSumCalculator(ingredients);
		break;
	case 'combined':
		break;
	default:
		console.error(getText('unknownIngredientCategory', 'errorTexts') + ': ' + categoryId);
		break;
	}

	return sum;
};

/**
 * Converts a points sum to a 1-10 scale, based on either a specific or a general scale
 * @param {Array} scalarList 
 */
export function generalPointCalculator (sum, scalarList) {
	let result = 0;

	if (scalarList) {
		const sumIndex = scalarList.sumArray.findIndex((val) => {
			return val === sum;
		});

		if (sumIndex !== -1) {
			result = scalarList.resultArray[sumIndex];
		};
	}

	return result;
};

/**
 * Calculates maximum possible points based on mission amount
 * @param {Array} missions 
 */
export function getMaxPoints (missions) {
	// TEMPORARY SOLUTION FOR SIMPLE MAX POINTS CALCULATIONS
	// WILL STOP FUNCTIONING PROPERLY IF MORE MISSIONS FOR SAME CATEGORIES ARE ADDED
	let base = 40;
	if (missions) {
		missions.forEach((mission) => {
			base += 5;
		});
	}

	return base;
}

export function getPointThreshold (pointPercentage) {
	let pointIndex = 1;
	resultThresholds.forEach((threshold, index) => {
		if (pointPercentage >= threshold) {
			pointIndex = index + 1;
		}
	});

	return pointIndex;
}
import React, { useEffect, useState } from 'react';
import './saved-dishes.scss';
import PropTypes from 'prop-types';
import { getText } from 'helpers/ui-text-helper';
import Plate from '../plate/plate';
import { getMaxPoints, getPointThreshold } from 'helpers/point-helper';

const SavedDishes = ({playerData, updatePlayerData}) => {
	const [showRightDishScroll, setShowRightDishScroll] = useState(false);
	const [showLeftDishScroll, setShowLeftDishScroll] = useState(false);

	const [currentIndex, setCurrentIndex] = useState(null);

	// On Init
	useEffect(() => {
		let currentDishIndex = 0;
		if (playerData && playerData.dishIndex !== undefined) {
			currentDishIndex = playerData.dishIndex;
		} else if (playerData && playerData.savedDishes) {
			currentDishIndex = playerData.savedDishes.length - 1;
		}

		setCurrentIndex(currentDishIndex);
	
		if (playerData.savedDishes) {
			const listElement = document.getElementById('savedDishes');
			if (listElement) {
				const listRect = listElement.getBoundingClientRect();

				const dishElement = document.getElementById('dish-' + currentDishIndex);

				if (dishElement) {
					const rect = dishElement.getBoundingClientRect();
					const rectCenter = rect.left + ((rect.right - rect.left) / 2);
					const listCenter = listRect.left + ((listRect.right - listRect.left) / 2);

					const scrollAmount = rectCenter - listCenter;

					listElement.scrollBy(
						{
							top: 0,
							left: scrollAmount,
							behavior: 'instant',
						}
					);
				}

				const leftElement = document.getElementById('dish-0');
				const lastId = playerData.savedDishes.length - 1;
				const rightElement = document.getElementById('dish-' + lastId);

				if (leftElement && rightElement) {
					const leftRect = leftElement.getBoundingClientRect();
					const rightRect = rightElement.getBoundingClientRect();
		
					const leftBound = listElement.getBoundingClientRect().left;
					const rightBound = listElement.getBoundingClientRect().right;

					setShowLeftDishScroll(leftRect.left <= leftBound);
					setShowRightDishScroll(rightRect.right >= rightBound);
				}
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (playerData && playerData.dishIndex) {
			setCurrentIndex(playerData.dishIndex);
		}
	}, [playerData]);

	const handleLoadDish = (index) => {
		const newIngredientChoices = [...playerData.savedDishes[index].ingredientChoices];
		updatePlayerData({ingredientChoices: newIngredientChoices, dishIndex: index});
		setCurrentIndex(index);
	};

	/**
	 * Scrolls back and forth depending on given direction
	 * @param {number} direction 
	 */
	const handleDishScroll = (direction) => {
		const element = document.getElementById('savedDishes');
		const elementWidth = element.clientWidth;
		// 25% of screen width as scroll setting
		const scrollAmount = elementWidth / 100 * 15 * direction;

		element.scrollBy(
			{
				top: 0,
				left: scrollAmount,
				behavior: 'instant',
			});

		const leftElement = document.getElementById('dish-0');
		const lastId = playerData.savedDishes.length - 1;
		const rightElement = document.getElementById('dish-' + lastId);

		if (leftElement && rightElement) {
			setShowLeftDishScroll(element.scrollLeft > 0);
			setShowRightDishScroll(element.scrollLeft < element.scrollWidth - elementWidth);
		}
	};

	return (
		<div className='SavedDishes'>
			<div id='savedDishes' className='SavedDishes-dishList'>
				{playerData.savedDishes.map((dish, index) => {
					let resultValueIndex = null;
					
					if (dish.combinedResult) {
						const percentage = dish.combinedResult / getMaxPoints(playerData.missions) * 100;
						resultValueIndex = getPointThreshold(percentage);
					}

					return (
						<div key={index}
							className='SavedDishes-savedDishWrapper'
							onClick={() => {handleLoadDish(index);}}
						>
							<div id={'dish-' + index} className='SavedDishes-savedDish'>
								{currentIndex === index &&
									<div className='SavedDishes-currentDish'/>
								}
								<div className='SavedDishes-dishImage'>
									<Plate playerData={dish} sizeClass={'small'}/>
								</div>
								<div className='SavedDishes-dishBottom'>
									<span>{getText('dish2', 'gameUi') + ' ' + (index + 1)}</span>
								</div>
								{resultValueIndex !== null &&
									<div className={'SavedDishes-dishSmiley smiley-' + resultValueIndex}/>
								}
							</div>
						</div>
					);
				})}
			</div>

			<div className='SavedDishes-dishNavigation'>
				{showRightDishScroll &&
					<div className='SavedDishes-dishScroll right'
						onClick={(e) => {
							e.stopPropagation();
							handleDishScroll(1);
						}}
					/>
				}
				{showLeftDishScroll &&
					<div className='SavedDishes-dishScroll left' 
						onClick={(e) => {
							e.stopPropagation();
							handleDishScroll(-1);
						}}
					/>
				}
			</div>
		</div>
	);
};

SavedDishes.propTypes = {
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
};

export default SavedDishes;
import './choose-recipe.scss';
import { recipesData } from 'data/recipe-data';
import React from 'react';
import PropTypes from 'prop-types';
import Choice from 'components/ui/choice/choice';
import { getText } from 'helpers/ui-text-helper';
import Button from 'components/ui/button/button';

const ChooseRecipe = ({playerData, updatePlayerData, title, goToNextPage}) => {
	/**
	 * Handle choosing recipe and start mission choice.
	 * @param {string} recipeId 
	 */
	const handleChooseRecipe = (recipeId) => {
		if (playerData && playerData.recipeId === recipeId) {
			updatePlayerData({recipeId: null});
		} else {
			updatePlayerData({recipeId: recipeId});
		} 
	};

	return (
		<div className='ChooseRecipe'>
			<div className='ChooseRecipe-logoAbsalon'/>
			<div className='ChooseRecipe-title'>
				<span>{title}</span>
			</div>
			<div className='ChooseRecipe-recipes'>
				{recipesData.map((recipe, index) => {
					let isSelected = false;
					if (playerData && playerData.recipeId === recipe.id) {
						isSelected = true;
					}

					return (
						<div key={index}
							className={'ChooseRecipe-recipeButton ' + (recipe.disabled ? 'isDisabled' : '')}>
							<Choice
								isSelected={isSelected}
								title={recipe.name} 
								onClick={() => {handleChooseRecipe(recipe.id);}} 
								clickDelay={150}
								imageId={recipe.id}
							/>
						</div>
					);
				})}
			</div>
			<div className='ChooseRecipe-navigation'>
				<Button
					text={getText('continue', 'generalUi')}
					isDisabled={!playerData || playerData.recipeId === undefined || playerData.recipeId === null}
					classes={['basicGradient', 'small', 'responsive', 'image', 'continue', 'right']}
					onClick={() => {goToNextPage();}}
				/>
			</div>
		</div>
	);
};

ChooseRecipe.propTypes = {
	playerData: PropTypes.object,
	updatePlayerData: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	goToNextPage: PropTypes.func.isRequired,
};

export default ChooseRecipe;
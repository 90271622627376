import React from 'react';
import './particles.scss';
import PropTypes from 'prop-types';

const Particles = ({particleAmount, spread}) => {
	const particleHolder = [...Array(particleAmount)];

	return (
		<div className='Particles'>
			{particleHolder.map((particle, index) => {
				const randPosition = Math.random() * 0.1;
				const randDuration = Math.random() + 2;
				const randDuration2 = Math.random() + 0.75;
				const randSize = Math.random() * 0.1;

				return (
					<div key={index}
						className='Particles-particleWrapper'
						style={{
							left: (index % spread) * randPosition + 'em',
							animationDelay: (index % 50) + 'ms',
							animationDuration: (index % 50) * randDuration + 's'
						}}
					>
						<div className='Particles-particle'
							style={{
								animationDelay: (index % 50) * 0.15 + 's',
								animationDuration: randDuration2 + 's'
							}}
						>
							<div className='Particles-particleImage'
								style={{
									animationDelay: (index % 50) * 0.15 + 's',
									animationDuration: randDuration2 + 's',
									width: (index % 20) * randSize + 'em',
									height: (index % 20) * randSize + 'em'
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

Particles.propTypes = {
	particleAmount: PropTypes.number.isRequired,
	spread: PropTypes.number.isRequired
};

export default Particles;
const { default: ChooseMissions } = require("components/game-pages/choose-missions/choose-missions");
const { default: ChooseRecipe } = require("components/game-pages/choose-recipe/choose-recipe");
const { default: Cooking } = require("components/game-pages/cooking/cooking");

const gamePagesData = {
	chooseRecipe: {
		component: ChooseRecipe,
		nextPage: 'chooseMissions',
		showChoiceInfo: false,
		id: 'recipe',
		title: 'Vælg en ret',
	},
	chooseMissions: {
		component: ChooseMissions,
		nextPage: 'cooking',
		previousPage: 'chooseRecipe',
		showChoiceInfo: true,
		id: 'mission',
		title: 'Vælg mission',
	},
	cooking: {
		component: Cooking,
		previousPage: 'chooseMissions',
		id: 'cooking',
		showChoiceInfo: true,
		title: 'Sammensæt ret'
	}
};

module.exports = {gamePagesData};
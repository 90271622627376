import React from 'react';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	LinearGradient,
	Stop,
	Svg,
	Defs,
	Rect,
	Font,
	Line,
	Image,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { getIngredientById, getIngredientDetailsById } from 'helpers/ingredient-helper';
import { getRecipeFromId } from 'helpers/recipe-helper';
import { getMissionFromId } from 'helpers/mission-helper';
import priceBadge from '../../assets/images/pdf-images/badgePrice.png';
import co2Badge from '../../assets/images/pdf-images/badgeCo2.png';
import tasteBadge from '../../assets/images/pdf-images/badgeTaste.png';
import priceBadgeFail from '../../assets/images/pdf-images/badgePriceFail.png';
import co2BadgeFail from '../../assets/images/pdf-images/badgeCo2Fail.png';
import tasteBadgeFail from '../../assets/images/pdf-images/badgeTasteFail.png';
import smiley from '../../assets/images/pdf-images/smiley.png';
import smileySad from '../../assets/images/pdf-images/smiley-sad.png';
import smileySaddest from '../../assets/images/pdf-images/smiley-saddest.png';
import smileyGlad from '../../assets/images/pdf-images/smiley-glad.png';
import smileyGladdest from '../../assets/images/pdf-images/smiley-gladdest.png';
import { getMaxPoints, getPointThreshold } from 'helpers/point-helper';
import { getText } from 'helpers/ui-text-helper';

// Colors
const lightGreen = '#CEDBB7';
const darkGreen = '#487D28';
const green1 = '#75BF4A';
const green2 = '#5C9C37';
const textDark = '#041F4F';
const beigeLight = '#FFF6ED';
const red = '#FC4B46';
const yellow = '#E8B329';
const green = '#21B536';

// Create styles
const styles = StyleSheet.create ({
	page: {
		padding: '6.3mm',
		fontFamily: 'omnes-pro'
	},
	column: {
		padding: '3.8mm',
		flexDirection: 'column',
		height: '284mm',
		border: 'solid',
		borderColor: green2,
		borderWidth: '1mm',
		borderTopLeftRadius: '5mm',
		borderTopRightRadius: '5mm',
		borderBottomRightRadius: '5mm',
		borderBottomLeftRadius: '5mm',
	},
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	section: {
		flexDirection: 'column',
	},
	gradientBackground: {
		borderTopLeftRadius: '3mm',
		borderTopRightRadius: '3mm',
		borderBottomRightRadius: '3mm',
		borderBottomLeftRadius: '3mm',
	},
	title: {
	  fontSize: '18',
	  paddingLeft: '3mm',
	  color: 'white',
	  fontWeight: 'bold',
	},
	boxContent: {
	  padding: '3mm',
	  borderBottomRightRadius: '5mm',
	  borderBottomLeftRadius: '5mm',
	  borderTopRightRadius: '5mm',
	  borderTopLeftRadius: '5mm',
	  borderBottomColor: darkGreen,
	  borderBottomStyle: 'solid',
	  borderBottomWidth: '2mm',
	  backgroundColor: green2,
	  marginBottom: '5mm',
	},
	beigeComponent: {
		width: '86mm',
		backgroundColor: beigeLight,
		padding: '3mm',
		marginTop: '3mm',
		borderTopLeftRadius: '3mm',
		borderTopRightRadius: '3mm',
		borderBottomRightRadius: '3mm',
		borderBottomLeftRadius: '3mm',
	},
	greenComponent: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		backgroundColor: lightGreen,
		borderTopLeftRadius: '3mm',
		borderTopRightRadius: '3mm',
		borderBottomRightRadius: '3mm',
		borderBottomLeftRadius: '3mm',
		paddingLeft: '2mm',
		paddingRight: '2mm',
		paddingBottom: '1mm',
		paddingTop: '1mm',
	},
	resultGradient: {
		borderTopLeftRadius: '3mm',
		borderTopRightRadius: '3mm',
		borderBottomRightRadius: '3mm',
		borderBottomLeftRadius: '3mm',
		paddingLeft: '2mm',
		paddingRight: '2mm',
		paddingBottom: '1mm',
		paddingTop: '2mm',
		height: '5mm',
		width: '80mm',
	},
	greenComponentEntries: {
		fontSize: '12',
		fontWeight: 'semibold',
		color: textDark,
		paddingLeft: '3mm',
	},
	beigeComponentTitles: {
		fontSize: '16',
		fontWeight: 'semibold',
		color: textDark,
		paddingLeft: '3mm',
		textTransform: 'capitalize',
	},
	choiceEntry: {
		fontSize: '18',
		fontWeight: 'bold',
		color: textDark,
		paddingLeft: '3mm',
		paddingRight: '3mm',
	},
	choiceEntryGreen: {
		fontSize: '18',
		fontWeight: 'bold',
		backgroundColor: lightGreen,
		color: textDark,
		paddingLeft: '3mm',
		paddingRight: '3mm',
	},
});

Font.register({
	family: 'omnes-pro',
	fonts: [
		{
			src: 'https://use.typekit.net/af/0bc945/000000000000000077359c15/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3.woff2'
		},
		{
			src: 'https://use.typekit.net/af/b7f08e/000000000000000077359bea/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3.woff2',
			fontWeight: 'bold'
		},
		{
			src: 'https://use.typekit.net/af/b6e4b0/000000000000000077359c3e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3.woff2',
			fontWeight: 'semibold'
		}
	]
});

// Create Document Component
const RecipeDocument = ({playerData, results}) => {
	const ingredients = playerData.ingredientChoices;
	const recipe = getRecipeFromId(playerData.recipeId);

	return (
		<Document>
			<Page size={'A4'} style={styles.page}>
				<View style={styles.column}>
					<View style={styles.row}>
						{/* LEFT SECTION */}
						<View style={styles.section}>
							{/* CHOICE INFO BOX */}
							<View style={styles.boxContent}>

								<Text style={styles.title}>{getText('choice', 'gameUi')}</Text>
								{/* RECIPE CHOICE */}
								<View style={styles.beigeComponent}>
									<Text style={styles.beigeComponentTitles}>{getText('dish', 'gameUi')}</Text>
									<View style={styles.greenComponent}>
										<Text style={styles.choiceEntry}>{recipe.name}</Text>
									</View>
								</View>
								
								{/* MISSIONS */}
								{(playerData.missions && playerData.missions.length > 0) &&
									<View style={styles.beigeComponent}>
										<Text style={styles.beigeComponentTitles}>
											{playerData.missions.length > 1 ? 'Missioner:' : 'Mission:'}
										</Text>
										<View style={{
											...styles.greenComponent,
											backgroundColor: beigeLight,
											padding: '0mm'
										}}>
											{playerData.missions.map((mission, index) => {
												const missionData = getMissionFromId(mission);
												return (
													<Text key={index}
														style={{
															...styles.choiceEntry, 
															backgroundColor: lightGreen,
															margin: '0.5mm',
															borderTopLeftRadius: '3mm',
															borderTopRightRadius: '3mm',
															borderBottomRightRadius: '3mm',
															borderBottomLeftRadius: '3mm'
														}}>
														{missionData.title}
													</Text>
												);
											})}
										</View>
									</View>
								}
							</View>

							{/* INGREDIENTS */}
							<View style={styles.boxContent}>
								<Text style={styles.title}>{getText('finishedRecipe', 'gameUi')}</Text>
								<Text style={styles.title}>{getText('recipeAmount', 'gameUi')}</Text>
								
								{(recipe && recipe.componentLists) && recipe.componentLists.map((component, index) => {
									const playerChoice = playerData.ingredientChoices.find((choice) => {
										return choice.componentId === component.id;
									});
									let ingredientData = null;
									if (playerChoice) {
										ingredientData = getIngredientById(playerChoice.ingredientId);
									}

									const componentName = 'Komponent ' + 
										(index + 1) + 
										(component.name ? ': ' + component.name : '');

									return (
										<View key={index} style={styles.beigeComponent}>
											<Text style={styles.beigeComponentTitles}>
												{componentName}
											</Text>
											<View style={styles.greenComponent}>
												<Text style={styles.greenComponentEntries}>
													{ingredientData.name}
												</Text>
											</View>
										</View>
									);
								})}
							</View>
						</View>
						{/* RIGHT SECTION */}
						<View style={styles.section}>
							<View style={styles.boxContent}>
								<Text style={styles.title}>{getText('result', 'gameUi')}</Text>

								{results.map((result, index) => {
									if (result.category.id === 'combined') {
										// Getting threshold for showing correct smiley relative to result
										const resultValue = result.value.total ? result.value.total : result.value;
										const percentage = resultValue / getMaxPoints(playerData.missions) * 100;
										const pointThreshold = getPointThreshold(percentage);
										let smileySrc = 1;
										let resultColor;
										switch (pointThreshold) {
										case 1:
											smileySrc = smileySaddest;
											resultColor = '#FC4B46';
											break;
										case 2:
											smileySrc = smileySad;
											resultColor = '#EF8834';
											break;
										case 3:
											smileySrc = smiley;
											resultColor = '#EAC500';
											break;
										case 4:
											smileySrc = smileyGlad;
											resultColor = '#ABB32C';
											break;
										case 5:
											smileySrc = smileyGladdest;
											resultColor = '#21B536';
											break;
										default:
											smileySrc = smiley;
											resultColor = '#EAC500';
										}

										return (
											<View key={index}>
												<Svg height='3' width='243'
													style={{paddingTop: '4mm', paddingBottom: '2mm'}}
												>
													<Line
														x1='0'
														y1='0'
														x2='243'
														y2='0'
														strokeWidth={2}
														stroke={green1}
													/>
												</Svg>

												{/* COMPLETED MISSIONS */}
												{playerData.missions &&
													<View style={styles.beigeComponent}>
														<Text style={{
															...styles.beigeComponentTitles,
															textTransform: 'none'
														}}>
															{getText('finishedMissions', 'gameUi')}
														</Text>

														<View style={{flexDirection: 'row', justifyContent: 'center'}}>
															{playerData.missions.map(
																(mission, i) => {
																	const isCompleted = result.value.completedMissions
																		.findIndex((completedMission) => {
																			return completedMission === mission;
																		}) !== -1;

																	let src;
																	switch (mission) {
																	case 'cheap':
																		src = isCompleted ? priceBadge : priceBadgeFail;
																		break;
																	case 'climate':
																		src = isCompleted ? co2Badge : co2BadgeFail;
																		break;
																	case 'tasty':
																		src = isCompleted ? tasteBadge : tasteBadgeFail;
																		break;
																	default: src = priceBadge;
																	}

																	return (
																		<Image key={i}
																			src={src}
																			style={{
																				flexDirection: 'row',
																				width: '20mm',
																				height: '25mm',
																				marginLeft: '2mm',
																				marginRight: '2mm'
																			}}
																			cache={false}
																		/>
																	);
																}
															)}
														</View>
													</View>
												}
												{/* FINAL SCORE */}
												<View style={styles.beigeComponent}>
													<Text style={{
														...styles.beigeComponentTitles,
														textTransform: 'none'
													}}>
														{'Samlet score'}
													</Text>

													<View style={{
														flexDirection: 'row', 
														justifyContent: 'center', 
														alignItems: 'center'
													}}>
														<View style={{
															flexDirection: 'column'
														}}>
															<Text style={{
																color: resultColor,
																fontWeight: 'bold',
																fontSize: '20mm',
															}}>
																{resultValue}
															</Text>
															<Text style={{fontWeight: 'semibold', color: textDark}}>
																{
																	getText('outOf', 'gameUi') + 
																	getMaxPoints(playerData.missions)
																}
															</Text>
														</View>
														<Image
															src={smileySrc}
															style={{
																flexDirection: 'row',
																width: '20mm',
																height: '20mm',
																marginLeft: '15mm',
															}}
															cache={false}
														/>
													</View>
												</View>
											</View>
										);
									}

									const value = result.value.total ? 
										result.value.total
										:
										result.value;

									return (
										<View key={index} style={styles.beigeComponent}>
											<Text style={styles.beigeComponentTitles}>
												{result.category.name}
											</Text>
											<Text style={{
												position: 'absolute',
												fontWeight: 'bold',
												zIndex: 1,
												color: '#FFFFFF',
												fontSize: 14,
												right: '37mm',
												top: '10.5mm',
											}}>
												{value + '/10'}
											</Text>
											{/* RESULT GRADIENT */}
											<Svg style={{
												position: 'relative',
												height: '5mm',
												width: '80mm',
												zIndex: 3,
												marginTop: '1mm',
												borderTopLeftRadius: '3mm',
												borderTopRightRadius: '3mm',
												borderBottomRightRadius: '3mm',
												borderBottomLeftRadius: '3mm',
											}}>
												<Defs>
													<LinearGradient id="resultGradient">
														<Stop offset="0%" stopColor={red} />
														<Stop offset="30%" stopColor={yellow} />
														<Stop offset="100%" stopColor={green} />
													</LinearGradient>
												</Defs>

												<Rect 
													style={styles.resultGradient}
													fill="url('#resultGradient')" 
												/>
											</Svg>
											{/* BACKGROUND FILL FOR BORDER */}
											<View style={{
												position: 'absolute',
												zIndex: 4,
												width: '81.5mm',
												height: '6.5mm',
												right: '2.25mm',
												top: '11.05mm',
												backgroundColor: '#041F4F',
												borderTopRightRadius: '4mm',
												borderBottomRightRadius: '4mm',
												borderTopLeftRadius: '4mm',
												borderBottomLeftRadius: '4mm',
											}}/>
											{/* RESULT GRADIENT COVER */}
											<View style={{
												position: 'absolute',
												zIndex: 2,
												width: (8 * (10 - value)) + 'mm',
												height: '5mm',
												right: '3mm',
												top: '11.75mm',
												backgroundColor: '#436980',
												borderTopRightRadius: '3mm',
												borderBottomRightRadius: '3mm',
												borderTopLeftRadius: (value === 0 ? '3mm' : '0'),
												borderBottomLeftRadius: (value === 0 ? '3mm' : '0'),
											}}/>
										</View>
									);
								})}
							</View>
						</View>
					</View>
				</View>
			</Page>
			<Page size={'A4'} style={styles.page}>
				<View style={styles.column}>
					<View style={styles.row}>
						<View style={{...styles.boxContent, padding: '0mm', height: '268mm'}}>
							<Text style={{...styles.title, margin: '3mm'}}>Ingredienser</Text>
							<View style={{
								...styles.section,
								height: '268mm',
								flexWrap: 'wrap',
							}}>
								{ingredients.map((ingredient, index) => {
									const ingredientDetails = getIngredientDetailsById(ingredient.ingredientId);
									const ingredientData = getIngredientById(ingredient.ingredientId);

									// Details not found
									if (!ingredientDetails) {
										return (
											<View key={index}>
											</View>
										);
									}

									return (
										<View key={index} style={{
											...styles.beigeComponent, 
											width: '89mm',
											marginLeft: '2.5mm',
											marginRight: '2.5mm'
										}}>
											<Text style={{...styles.beigeComponentTitles, fontSize: '14'}}>
												{ingredientData.name}
											</Text>
											<View style={styles.greenComponent}>
												{ingredientDetails.ingredients.map((details, i) => {
													let name = '';
													if (details.names) {
														name = details.names.find((detail) => {
															return detail.recipeId === playerData.recipeId;
														}).name;
													} else {
														name = details.name;
													}

													let amount = '';
													if (details.amounts) {
														amount = details.amounts.find((detail) => {
															return detail.recipeId === playerData.recipeId;
														}).amount + ' ' + details.unit;
													} else {
														amount = details.amount + ' ' + details.unit;
													}

													return (
														<View key={i}
															style={{
																flexDirection: 'row', 
																justifyContent: 'space-between',
																width: '82mm'
															}}>
															<Text style={{
																fontSize: '13',
																fontWeight: 'semibold',
																color: textDark,
																width: '70mm', 
															}}>
																{name}
															</Text>
															<Text style={{
																fontSize: '13',
																fontWeight: 'semibold',
																color: textDark
															}}>
																{amount}
															</Text>
														</View>
													);
												})}
											</View>
										</View>
									);
								})}
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

RecipeDocument.propTypes = {
	playerData: PropTypes.object.isRequired,
	results: PropTypes.array.isRequired,
};

export default RecipeDocument;